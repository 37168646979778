import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SecurePicture, { GetBusinessCardImage } from "../../functions/profilepicture";
import { GetMyBusinessCardFolder } from "../../Services/BusinessCardService";
import UserListLoader from "./Loaders/UserListLoader";

export default function MyBusinessCardFolder() {
    const auth = useSelector(x => x.authSlice);
    const [businessCards, setBusinessCards] = useState(false);
    const [isShowBusinessCardModal, setBusinessCardModal] = useState(false);
    const [isShowBusinessCard, setShowBusinessCard] = useState(false);
    const [businessCardImage, setBusinessCardImage] = useState("");

    useEffect(() => {
        GetMyBusinessCardFolder(3, (resp) => { setBusinessCards(resp.data) });
    }, []);

    const showBusinessCard = (image) => {
        setBusinessCardImage(image);
        setShowBusinessCard(true);
    }
    return (
        <>
            <Modal show={isShowBusinessCard} onHide={() => setShowBusinessCard(false)}>
                <Modal.Header closeButton>
                    <b>View Business Card</b>
                </Modal.Header>
                <Modal.Body>
                    <img src={GetBusinessCardImage(businessCardImage)} width="100%" className='rounded' />
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={() => setShowBusinessCard(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <div className="communtiy-box business-card-folder-box">
                <h2 className="box-title">MY BUSINESS CARD FOLDER</h2>

                {!businessCards ?
                    <UserListLoader />

                    :
                    businessCards.length == 0 ? <>
                    <center><img src="https://img.freepik.com/free-vector/seo-concept-illustration_114360-5846.jpg?w=826&amp;t=st=1670251163~exp=1670251763~hmac=b45fc687491740cdacee74cb05d8664941ef3eed786b26f071ba0bc5eca55123" width="150"/><br/>
                  <small>
                  <b> No cards in your folder</b><br/><br/>
                  </small>
                    
                    
                    </center>
                    </>
                        :
                        <>
                            {businessCards.map((item, i) =>
                                <div className="single-member-box" key={i}>
                                    <div className="member-img-box">
                                        <Link to={`/Profile/${item.user.id}`}>  <img className="single-member-img" src={SecurePicture(item.user.pictureImage)} /></Link>
                                    </div>
                                    <div className="community-details">
                                        <Link to={`/Profile/${item.user.id}`}><h3 className="member-name">{item.user.firstname}</h3></Link>
                                        <p className="member-title">Qualified Angel Investor, {item.user.country}</p>
                                        <a className="join-link" style={{ cursor: 'pointer' }} onClick={() => showBusinessCard(item.user.businessCardImage)} >View Business Card</a>
                                    </div>
                                </div>
                            )}
                        </>
                }
                {/* <div className="single-member-box">
                    <div className="member-img-box">
                        <img className="single-member-img" src="/assets/images/member-02.jpg" />
                    </div>
                    <div className="community-details">
                        <h3 className="member-name">Prof Inderjit Singh</h3>
                        <p className="member-title">Business Consultant, Singapore</p>
                        <a className="join-link" href="#">View Business Card</a>
                    </div>
                </div>

                <div className="single-member-box">
                    <div className="member-img-box">
                        <img className="single-member-img" src="/assets/images/member-03.jpg" />
                    </div>
                    <div className="community-details">
                        <h3 className="member-name">Feryal Nass</h3>
                        <p className="member-title">Startup Founder, Bahrain</p>
                        <a className="join-link" href="#">View Business Card</a>
                    </div>
                </div>


                <div className="single-member-box">
                    <div className="member-img-box">
                        <img className="single-member-img" src="/assets/images/member-04.jpg" />
                    </div>
                    <div className="community-details">
                        <h3 className="member-name">Dr Paul Doany</h3>
                        <p className="member-title">Faculty Member, Turkey</p>
                        <a className="join-link" href="#">View Business Card</a>
                    </div>
                </div> */}

                <div className="clearfix"></div>
                <p className="viewall-link">
                    {businessCards.length > 0 ? <Link to="/BusinessCards">+ View all</Link> : <center><Link to="/MyNetwork"><i className="fa fa-search"></i> View My Network </Link></center>}
                    
                </p>
            </div>
        </>

    );
}