import { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { GetMyPostCategories, GetRecentPosts } from "../../../Services/FeedService";
import UserListLoader from "../../Partials/Loaders/UserListLoader";
import Post from "./Post";


export default function Feed(props) {
    const auth = useSelector(state => state.authSlice);
    const [postList, setPostList] = useState([]);
    const [selectedCategory, changeSelectedCategory] = useState(null);
    const [categoryType, changeCategoryType] = useState(null);
    const [myCategories, setMyCategories] = useState([]);
    const [isLoaded, setLoadedStatus] = useState(false);
    const [page, setPage] = useState(1);
    const [isLoadingMoreData, setLoadingMoreData] = useState(false);
    const [newDataStatus, setNewDataStatus] = useState(true)
    const [isFetching, setFetching] = useState(false);
    const [postFilterType, setFilter] = useState(-1);
    const [filterTypeText, setFilterText] = useState("All Posts");
    const [isShwoFeedCardsModal, setShowFeedCardsModal] = useState(false);

    const handleScroll = () => {

        if ((window.innerHeight + document.documentElement.scrollTop + 50 >= document.documentElement.scrollHeight)) {
            setFetching(true);
            // setLoadingMoreData(true);
        }
    }


    const getPosts = (_page = 1, filterType = -1, userTypeID = -1, categoryType = -1) => {

        GetRecentPosts(props.userID, _page, filterType, userTypeID,categoryType, (resp) => {

            if (resp.data.length == 0 && _page == 1) {
                setPostList([]);
                setNewDataStatus(false);
            }
            else if (resp.data.length == 0) {
                setNewDataStatus(false);
            }
            else {

                setNewDataStatus(true);
                if (_page == 1) {
                    setPostList(resp.data);
                }
                else {
                    setPostList([...postList, ...resp.data]);
                }
            }
            setLoadedStatus(true);
            setLoadingMoreData(false);
            setFetching(false);




        });
    }

    useEffect(() => {

        setPostList([]);
        setPage(1);
        setLoadedStatus(false);
        setLoadingMoreData(true);
        setNewDataStatus(true);
        // setFetching(true);
        getPosts(1);
    }, [props.userID]);

    const changeCategory = (relType,category) => {
        setPostList([]);
        setPage(1);
        setLoadedStatus(false);
        setLoadingMoreData(true);
        setNewDataStatus(true);
        changeCategoryType(relType);
        changeSelectedCategory(category);
        // setFetching(true);
        getPosts(1, postFilterType, category,relType);
        setShowFeedCardsModal(false);
        // console.log(relType);
        // console.log(category);
    }


    useEffect(() => {
        if (isLoaded == false) {
            return;
        }
        setLoadedStatus(false);
        setLoadingMoreData(false);
        setNewDataStatus(true);
        setPage(1);
        getPosts(1, postFilterType, selectedCategory, categoryType);


    }, [postFilterType]);

    useEffect(() => {

        if (isLoaded == true && isLoadingMoreData == false && newDataStatus == true) {
            setLoadingMoreData(true);
            getPosts((page + 1), postFilterType, selectedCategory, categoryType);
            setPage(prev => prev + 1);
        }
    }, [isFetching]);

    useEffect(() => {
        GetMyPostCategories((resp) => {
            if (resp.status) {
                setMyCategories(resp.data);
            }
        });

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);


    }, [])

    const handlerFilter = (type, text) => {
        setFilter(type);
        setFilterText(text);
    }
    return (
        <>

            {props.isMyProfile ? <>

                <Modal show={isShwoFeedCardsModal} onHide={() => setShowFeedCardsModal(false)}>
                    <Modal.Header closeButton>
                        <b>Select Feed</b>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="community-filter forModal" style={{flexDirection:'column', marginTop:0}}>
                            <li className={null == selectedCategory ? "all" : ""} style={{ flexBasis: '10%' }} onClick={() => changeCategory(null)}>All Posts</li>
                            {myCategories.map((item, i) => <li onClick={() => changeCategory(item.type,item.relID)} className={item.relID == selectedCategory  && item.type == categoryType  ? "all" : ""} key={i}>{item.categoryName}</li>)}
                          
                        </div>


                    </Modal.Body>

                </Modal>



                <div className="community-filter">
                    <li className={null == selectedCategory ? "all" : ""} style={{ flexBasis: '10%' }} onClick={() => changeCategory(null)}>All Posts</li>
                    {myCategories.slice(0, 3).map((item, i) => <li onClick={() => changeCategory(item.type, item.relID)} className={item.relID == selectedCategory && item.type == categoryType ? "all" : ""} key={i}>{item.categoryName}</li>)}
                    {
                        myCategories.length > 3 ? <>
                            <li className="more" onClick={() => setShowFeedCardsModal(true)}>
                                <i className="fa fa-ellipsis-h"></i>
                            </li>
                        </> : ""
                    }
                </div>


            </> :
                <div className="community-filter">
                    <li className="all">All Posts</li>


                </div>

            }
            <div className="post-filter-wrapper">

                <span>
                    <Dropdown align="end">
                        <Dropdown.Toggle variant="default" id="dropdown-basic" >
                            <i className="fa fa-filter"></i> {filterTypeText}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item style={{ cursor: 'pointer' }} onClick={() => handlerFilter(-1, "All Posts")} > All Posts</Dropdown.Item>
                            <Dropdown.Item style={{ cursor: 'pointer' }} onClick={() => handlerFilter(0, "Only Celebrations")} >Only Celebrations</Dropdown.Item>
                            <Dropdown.Item style={{ cursor: 'pointer' }} onClick={() => handlerFilter(1, "No Celebrations")}  >No Celebrations</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>


                </span>
            </div>

            {isLoaded ? <>
                {postList.length == 0 ?
                    <>
                        <div className="post" style={{ borderRadius: '10px' }}>
                            <center>
                                <img src="https://img.freepik.com/free-vector/empty-concept-illustration_114360-1233.jpg?w=826&t=st=1671017680~exp=1671018280~hmac=1d4f1b4de6ddfa38a47ca6d62bef36e7c508a6cae826d9f4e54e03258ae00509" width="30%" />
                                <p>
                                    <b>
                                        Hasn't published any posts yet
                                    </b>
                                </p>
                            </center>
                        </div>

                    </>
                    :
                    <>




                        {postList.map((item, i) => <Post key={i} item={item} />)}
                        {isLoadingMoreData ? <>
                            <div style={{ backgroundColor: 'white', padding: 5, marginBottom: 10 }}>
                                <ContentLoader />
                            </div>
                        </> : ""}

                        {newDataStatus == false ? <>
                            <div style={{ backgroundColor: 'white', padding: 20, marginBottom: 10 }}>
                                No more posts to show
                            </div>
                        </> : ""}
                    </>


                }
            </> :
                <>
                    <div style={{ backgroundColor: 'white', padding: 5, marginBottom: 10 }}>
                        <ContentLoader />
                    </div>
                    <div style={{ backgroundColor: 'white', padding: 5, marginBottom: 10 }}>
                        <ContentLoader />
                    </div>
                    <div style={{ backgroundColor: 'white', padding: 5, marginBottom: 10 }}>
                        <ContentLoader />
                    </div>

                </>
            }




        </>
    )
}