export function CreatePublicURL(name,id)
{
    return 'https://wbaf.me/'+ReplaceStr(name)+"/"+id;
}


function ReplaceStr(name)
{
    name = name.toLowerCase();
    name = name.replaceAll(" ","");
    name = name.replaceAll("ı","i");
    name = name.replaceAll("ü","u");
    name = name.replaceAll("ş","s");
    name = name.replaceAll("ö","o");
    name = name.replaceAll("ç","c");
    name = name.replaceAll("ğ","g");
    name = name.replaceAll("'","");
    name = name.replaceAll(".","");
    return name;

}