import BaseRequest from "./RequestService";
import { UrlList } from "./UrlList";

export async function GetLastNotifications(page,callback) {
    BaseRequest(UrlList.GetNotifications+"?page="+page, callback)
}

export async function MarkAsReadNotification(id,callback) {
    BaseRequest(UrlList.MarkAsReadNotification+"?id="+id, callback)
}

