import { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BulletList } from "react-content-loader";
import Cropper from "react-easy-crop";
import { useSelector } from "react-redux";
import getCroppedImg from "../../functions/cropImage";
import { CreatePublicURL } from "../../functions/urlHelper";
import { SaveBiography } from "../../Services/UserProfileService";
import '../../profileupdate.css';
export default function ProfileUpdate() {
    const auth = useSelector(state => state.authSlice);

    const [myInformation, setMyInformation] = useState(false);
    const [show, setShow] = useState(false);
    const [profilePictureBlob, setProfilePictureBlob] = useState(false);
    const [businessCardBlob, setBusinessCardBlob] = useState(false);
    const [rotation, setRotation] = useState(0)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);


    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                profilePictureBlob,
                croppedAreaPixels,
                rotation
            )
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const handleInfo = (target) => {

        const id = target.id;
        var value = target.value;
        if (value.includes("http://") | value.includes("https://")) {
            value = value.replaceAll("http://", "").replaceAll("https://", "");
            target.value = value;
        }
        const newData = { ...myInformation };
        if (id == 'profilePicture') {
            setProfilePictureBlob(URL.createObjectURL(target.files[0]));
            newData[id] = target.files[0];
        }
        else if (id == 'businessCard') {
            setBusinessCardBlob(URL.createObjectURL(target.files[0]));
            newData[id] = target.files[0];
        }
        else if(id == 'emailPermission'){
            newData[id] = target.checked;
        }
        else {
            newData[id] = value;
        }
        setMyInformation(newData);
    }
    const handleSaveBiography = async () => {
        //showCroppedImage();
        if (croppedAreaPixels != null) {
            const croppedImage = await getCroppedImg(
                profilePictureBlob,
                croppedAreaPixels,
                rotation
            );
            //   setCroppedImage(croppedImage);
            myInformation["profilePicture"] = croppedImage;
        }
        SaveBiography(myInformation, function (resp) {
            window.location.reload();
        });

    };
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useState(() => {
        setMyInformation({
            biography: auth?.data?.biography,
            instagramURL: auth?.data?.instagramURL,
            youtubeURL: auth?.data?.youtubeURL,
            twitterURL: auth?.data?.twitterURL,
            facebookURL: auth?.data?.facebookURL,
            linkedinURL: auth?.data?.linkedinURL,
            webSiteURL: auth?.data?.webSiteURL,
        });

    }, [])
    return (
        <>
            <small><a onClick={handleShow} className="" style={{ cursor: 'pointer' }} href="#here">  <i className='fa fa-edit'></i>  Edit Your Profile</a></small>
            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-vertical'>

                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Biography:
                            </label>
                            <div className='col-md-9'>
                                <textarea id="biography" className='form-control' placeholder="Please enter your biography" rows="2" onChange={(e) => handleInfo(e.target)} defaultValue={myInformation.biography}></textarea>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Instagram:
                            </label>
                            <div className='col-md-9'>
                                <input type="text" className='form-control' id='instagramURL' onChange={(e) => handleInfo(e.target)} defaultValue={myInformation.instagramURL} />
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Twitter:
                            </label>
                            <div className='col-md-9'>
                                <input type="text" className='form-control' id='twitterURL' onChange={(e) => handleInfo(e.target)} defaultValue={myInformation.twitterURL} />
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Linkedin:
                            </label>
                            <div className='col-md-9'>
                                <input type="text" className='form-control' id='linkedinURL' onChange={(e) => handleInfo(e.target)} defaultValue={myInformation.linkedinURL} />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Facebook:
                            </label>
                            <div className='col-md-9'>
                                <input type="text" className='form-control' id='facebookURL' onChange={(e) => handleInfo(e.target)} defaultValue={myInformation.facebookURL} />
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Youtube:
                            </label>
                            <div className='col-md-9'>
                                <input type="text" className='form-control' id='youtubeURL' onChange={(e) => handleInfo(e.target)} defaultValue={myInformation.youtubeURL} />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Web Site:
                            </label>
                            <div className='col-md-9'>
                                <input type="text" className='form-control' id='webSiteURL' onChange={(e) => handleInfo(e.target)} defaultValue={myInformation.webSiteURL} />
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Wbaf.me:
                            </label>
                            <div className='col-md-9'>
                                <a className="btn btn-sm btn-secondary" href={CreatePublicURL(auth?.data?.firstname, auth?.data?.id)} target="_blank">
                                    {CreatePublicURL(auth?.data?.firstname, auth?.data?.id)}
                                </a>
                            </div>
                        </div>


                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Profile Picture:
                            </label>
                            <div className='col-md-9'>
                                <label for="profilePicture" className="custom-file-upload">
                                    <i className="fa fa-cloud-upload"></i> Upload New Photo
                                </label>
                                <input id="profilePicture" type="file" onChange={(e) => handleInfo(e.target)} />
                                {profilePictureBlob != false ?
                                    <div className="profile-update-preview" style={{ position: 'relative' }}>
                                        <Cropper
                                            classes={{ containerClassName: "profile-update-preview" }}
                                            image={profilePictureBlob}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={1}
                                            onCropChange={setCrop}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                        />

                                        {/* <img src={profilePictureBlob} width="50%" className="img-thumbnail rounded mt-2" /> */}
                                    </div> : ""}


                               {
                                croppedImage != null ?  <img src={croppedImage} width="100%" /> : ""
                               }
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Business Card:
                            </label>
                            <div className='col-md-9'>
                                <label for="businessCard" className="custom-file-upload">
                                    <i className="fa fa-cloud-upload"></i> Upload Business Card
                                </label>
                                <input id="businessCard" type="file" onChange={(e) => handleInfo(e.target)} />
                                {businessCardBlob != false ?
                                    <div className="preview">
                                        <img src={businessCardBlob} width="50%" className="img-thumbnail rounded mt-2" />
                                    </div> : ""}
                            </div>
                        </div>


                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                Password:
                            </label>
                            <div className='col-md-9 mt-1'>
                                <a href="https://id.wbaforum.org/MyProfile/ChangePassword" target="_blank" style={{ color: "blue", fontSize: '13px' }}>Click here to change your password.</a>
                            </div>
                        </div>

                        <div className='row mb-3'>
                            <label className='col-form-label col-md-3'>
                                E-Mail Notifications:
                            </label>
                            <div className='col-md-9 mt-1'>
                                <label className="switch">
                                    <input type="checkbox" id="emailPermission"  defaultChecked={auth?.data?.emailPermission} onChange={(e) => handleInfo(e.target)} />
                                    <span className="slider round"></span>
                                 
                                </label>

                             
                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveBiography}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

        </>

    )
} 