import BaseRequest from "./RequestService";
import { UrlList } from "./UrlList";

export async function GetCountries(callback) {
    BaseRequest(UrlList.GetCountries, callback)
}

export async function JoinCountryGroup(id,callback) {
    BaseRequest(UrlList.JoinCountryGroup+"?countryID="+id, callback)
}
