import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SecurePicture from "../../functions/profilepicture";
import { SearchUser } from "../../Services/ConversationService";
import ConversationNewMessage from "./ConversationNewMessage";

export default function NewConversation(props) {
    const [searchUsers, setSearchUsers] = useState([]);
    const [newUser, setNewUser] = useState(null);
    const navigate = useNavigate();
    const handleChangeSearch = (target) => {
        var value = target.value;
        SearchUser(value, (resp) => {
            setSearchUsers(resp.data);
        });
    }

    const setUser = (user) => {
        setNewUser(user);
    }

    const postedRedirect = (newConversationID) => {
        props.refreshConversationData(true);
        navigate("/Messages/" + newConversationID);
    }
const handleClearSearch = () =>{
    setUser(null);
    setSearchUsers([]);
}

    return (
        <>
            {
                newUser == null ? <>
                    <div className='message-title' >
                        <div className='message-user-name'>
                            <input type="text" className="form-control" placeholder="Type a name" onChange={(e) => handleChangeSearch(e.target)} />

                        </div>
                    </div>

                </>
                    :
                    <>
                        <div className='message-title' >
                            <img src={SecurePicture(newUser.pictureImage)} className='rounded-circle' width={32} height={32} style={{ marginRight: 10 }} />
                            <div className='message-user-name'>
                                <span>
                                    {newUser.firstname}
                                </span>

                                <div className='message-user-title'>
                                    {newUser.masterTitle}, {newUser.country}
                                </div>
                            </div>
                            <div className='message-tools' style={{ cursor: "pointer" }} onClick={handleClearSearch}>
                                <i className="fa fa-trash"></i>
                            </div>

                        </div>
                    </>
            }

            <div className="messages">
                {
      newUser == null ? searchUsers.length == 0 ? 
      <>
     <center>
     <img src="https://img.freepik.com/free-vector/usability-testing-concept-illustration_114360-1571.jpg?w=826&t=st=1672073687~exp=1672074287~hmac=ef96b03a57b5438e972341c585112c27218b296ee990385a8b821d7a744dfd77" width="50%" style={{opacity:"0.2"}}/>
     </center>
      </>
      
      
      : 

      <div className="search-result-list">
      {searchUsers.map((item, i) => <>
          <div className='search-user-item' onClick={() => setUser(item)}>
              <img src={SecurePicture(item.pictureImage)} className='rounded-circle' width={32} height={32} />
              <div className='search-user-name'>
                  <span>{item.firstname}</span>
              </div>

          </div>
      </>)}
  </div>
       : ""


                }

            </div>

            {newUser != null ? <ConversationNewMessage toUserId={newUser.id} loadEvent={postedRedirect} /> : ""}

        </>

    );
}