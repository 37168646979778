import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetMyCards } from '../../Services/UserProfileService';
import { setModalOpenState } from "../../stores/generalSlice";
import '../../welcomemodal.css';
export default function WelcomeModal() {
    const generalSlice = useSelector(state => state.generalSlice);
    const dispatch = useDispatch();
    const [isShow, setShowStatus] = useState(!generalSlice.isModalOpen);
    const [myCardList, setMyCardList] = useState([]);
    useEffect(() => {

        if (generalSlice.isModalOpen == false) {
            dispatch(setModalOpenState(true));
        }
        
        GetMyCards((resp) => {
            setMyCardList(resp.data);
        });


    }, []);


     const filteredItems = myCardList.filter((item) => [9, 10, 12, 13,23].includes(item.userType.id));


return (
   
<>

      <Modal show={isShow} onHide={() => setShowStatus(false)} dialogClassName="welcomeModal">
        <Modal.Body>
            <center>
            <a href="https://angelsweek.wbaforum.org/"> <img src="https://wbaf2024.org/upload/wbaf-popup-2.jpg" width="100%"  /></a>
            </center>
        </Modal.Body>
      </Modal>
</>
    
  );
};

