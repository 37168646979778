export default function SecurePicture(imageURL,size="md")
{
    if(imageURL == null)
    {
        return "https://network.wbaforum.org/assets/images/no-image-user.png";
    }
    else
    {
        if(size=="lg")
        {
            return "https://pool.wbaforum.org/images/users/"+imageURL;
        }
        else if(size=="md"){
            return "https://pool.wbaforum.org/images/users/300x300/"+imageURL;
        }
        else if(size=="sm" | size=="xs")
        {
            return "https://pool.wbaforum.org/images/users/100x100/"+imageURL;
        }
       
    }
}

export function GetBusinessCardImage(imageURL)
{
    if(imageURL == null)
    {
        return "https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-256.png";
    }
    else
    {
        return "https://pool.wbaforum.org/images/businesscards/"+imageURL;
    }
}

export function GetCardNamedCardURL(cardVariableName)
{
  
        return "https://id.wbaforum.org/contents/images/cards/withname/"+cardVariableName+".png";
    
}

export function GetCardBannerURL(cardVariableName)
{
  
        return "https://id.wbaforum.org/contents/images/cards/banner/"+cardVariableName+".jpg";
    
}

export function GetPreviewImage(url)
{
  
        return "https://api-network.wbaforum.org/preview/GetImage?url="+url;
    
}



