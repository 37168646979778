import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GetToken } from "../Services/RequestService";
import { UrlList } from "../Services/UrlList";

const initialState = {
    isLogon: false,
    info: null,
    loading: true
}
const config = {
    headers: {
        Authorization: "Bearer " + GetToken()
    }
};
export const getLogin = createAsyncThunk("getLogin", async () => {

    const response = await axios.get(UrlList.baseURL + "/UserProfile/GetMyProfile", config);
    return response.data;



})
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        markSeenNotifications:(state)=>{
            state.data.unseenNotificationCount = 0;
        },
        markSeenMessages:(state)=>{
            state.data.unseenMessageCount = 0;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLogin.pending, (state, action) => {
            state.isLogon = false;
            state.data = null;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(getLogin.fulfilled, (state, action) => {
            if (!action.payload.status) {
                window.location.href = 'https://id.wbaforum.org';
            }
            state.isLogon = action.payload.status;
            state.data = action.payload.data;
            state.loading = false;
            state.error = false;


        });
        builder.addCase(getLogin.rejected, (state, action) => {

            state.isLogon = false;
            state.data = null;
            state.loading = false;
            state.error = true;
            window.location.href = 'https://id.wbaforum.org';

        });
    }
})

 export const {  markSeenNotifications,markSeenMessages } = authSlice.actions
export default authSlice.reducer