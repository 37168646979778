import { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import GetEvents from "../../Services/ContentService";
import '../../eventlist.css';
export default function EventList() {


    const [isShowModal, setShowModal] = useState(false);
    const [dataList, setDataList] = useState([]);
    useEffect(() => {

        if (isShowModal == true && dataList.length == 0) {
            GetEvents((resp) => {
                if (resp.status) {
                    setDataList(resp.data);
                }
            })
        }
    }, [isShowModal])
    return (<>

        <Modal show={isShowModal} size="xl" onHide={() => setShowModal(false)}>

<Modal.Header>
    <b>Upcoming WBAF Events</b>
</Modal.Header>
            <Modal.Body>
                <table className="table table-border event-table table-hover">
                  
                    <tbody>
                        {
                            dataList.map((item, index) => <>
                                <tr>
                                    <td width="50">
                                        <div className="event-badge">     {index + 1}</div>

                                    </td>
                                    <td>
                                        {item.title}
                                    </td>
                                    <td width="120">
                                        {item.eventDate}  
                                    </td>
                                    <td width="120">
                                    {item.eventTime} GMT
                                    </td>
                                    <td width="80">
                                        <a className="btn btn-sm btn-success" href="https://id.wbaforum.org/MyContents/List/6" target="_blank"><i className="fa fa-plus"></i> Join</a>
                                    </td>
                                </tr>
                            </>)
                        }
                    </tbody>
                </table>

            </Modal.Body>
            <Modal.Footer>

                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>



        <OverlayTrigger
            key="events_icon_wrapper"
            placement="top"
            overlay={
                <Tooltip id={`events_icon_wrapper`}>
                    Events
                </Tooltip>
            }
        >
            <a className='btn btn-sm btn-secondary events-button' onClick={() => setShowModal(true)}>
                <i className='fa fa-calendar '></i> Upcoming WBAF Events
            </a>
        </OverlayTrigger>




    </>)
}