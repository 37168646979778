import { useEffect, useState } from "react"
import { GetCardNamedCardURL } from "../../functions/profilepicture"
import { GetMyCommittee } from "../../Services/UserTypeService"
import UserListLoader from "./Loaders/UserListLoader"

export default function MyCommittee() {

    const [myCommitteeData, setMyCommittee] = useState(null)
    useEffect(() => {
        GetMyCommittee((resp) => { setMyCommittee(resp.data) })
    }, [])
    return (
        <>
            <div className="communtiy-box world-leaders">
               
                <h2 className="box-title"> MY COMMITTEE</h2>
                {myCommitteeData == null ? <>
                    <UserListLoader />
                </> :


                    <div className="single-community-box">
                        <div className="community-img-box">
                            <img className="single-community-img" style={{ borderRadius: "5px" }} src={GetCardNamedCardURL(myCommitteeData.userType.variableName)} />
                        </div>
                        <div className="community-details" style={{display:'flex', alignItems:'center', height:'50px'}} >
                            <h3 className="community-name"> {myCommitteeData.userType.typeName}</h3>
                            {/* <p className="total-member">{props.item.cardCount} Members</p> */}

                            {/* <a className="join-link" href="https://wbaforum.org/represent" target="_blank" style={{ float: 'right' }}>View</a> */}


                        </div>
                    </div>


                }

            </div>
        </>

    )
}