import { UrlList } from "./UrlList";
import BaseRequest from './RequestService';

export async function GetUserTypes(category,pagesize,checkMyStatus,callback) {
    BaseRequest(UrlList.GetUserTypes+"?pageSize="+pagesize+"&category="+category+"&checkMyStatus="+checkMyStatus,callback);
}

export async function ConfirmJoinCard(cardID,callback) {
    BaseRequest(UrlList.ConfirmJoinCard+"?cardID="+cardID, callback)

}

export async function GetMyCommittee(callback) {
    BaseRequest(UrlList.GetMyCommittee, callback)

}