import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import SecurePicture from "../../../functions/profilepicture";
import moment from "moment";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { DeleteComment } from "../../../Services/FeedService";
export default function CommentItem(props) {
    const [isDeleted, setDeleted] = useState(false);
    const [isShowDeleteModal, setShowDeleteModal] = useState(false);
    useEffect(() => {


    }, []);

    const handleDelete = () => {
        DeleteComment(props.item.id, (resp) => {
            if (resp.status == true) {
                setShowDeleteModal(false);
                setDeleted(true);
            }
        })


    }

    return (
        <>
            <Modal show={isShowDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <b>Are you sure?</b>
                </Modal.Header>
                <Modal.Body>
                    <center>
                        <img src="https://img.freepik.com/free-vector/thoughtful-woman-with-laptop-looking-big-question-mark_1150-39362.jpg?w=826&t=st=1671038036~exp=1671038636~hmac=684e9b7c9afe2adb7f7dbfb87fdaace6e70a18847b0ed37d469ad47b5c3de4f2" width="50%" />
                        <h6>
                            This comment will be deleted, are you sure?
                        </h6>
                    </center>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="danger" onClick={handleDelete}>
                        Yes,Delete
                    </Button>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {!isDeleted ? <>

                <div className="post-comment-item">
                    <div className="post-comment-image">
                        <Link to={"/Profile/" + props.item.user.id}> <img src={SecurePicture(props.item.user.pictureImage)} width="48" className="rounded-circle" /></Link>
                    </div>
                    <div className="comment-text">
                        <div className="comment-header">
                            <div className="user-profile">
                                <Link to={"/Profile/" + props.item.user.id}> {props.item.user.firstname}</Link>

                                <div className="user-title">
                                    {props.item.user.masterTitle}, {props.item.user.country}
                                </div>
                            </div>
                            <div className="comment-tools">
                                <Dropdown align="end">
                                    <Dropdown.Toggle variant="default" id="dropdown-basic" >
                                        <i className="fa fa-ellipsis-h" aria-hidden="true" style={{ fontSize: '11px' }}></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setShowDeleteModal(true)}  ><i className="fa fa-trash"></i> Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                        </div>



                        {props.item.comment}
                        <div className="comment-date">
                            {moment.utc(props.item.date).local().startOf('seconds').fromNow()}
                        </div>
                    </div>

                </div>
            </> : ""}



        </>

    )
}