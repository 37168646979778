import { createSlice } from "@reduxjs/toolkit";

export const generalSlice = createSlice({
name: 'total',
initialState:{isModalOpen:false},
reducers:{
    setModalOpenState: (state,action) => {
        state.isModalOpen = action.payload
    }
}

});

export const {setModalOpenState} = generalSlice.actions

export default generalSlice.reducer 