import React, { Component, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from 'react-router-dom';
import { GetUserProfile, IsSavedBusinessCard, SaveBiography, SaveBusinessCardToFolder } from '../../Services/UserProfileService';
import OtherCardsComponent from '../Layout/OtherCardsComponent';
import CreatePost from '../Partials/CreatePost';
import MyBusinessCard from '../Partials/MyBusinessCard';
import MyBusinessCardFolder from '../Partials/MyBusinessCardFolder';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SecurePicture, { GetCardBannerURL } from '../../functions/profilepicture';
import Feed from '../Layout/FeedComponents/Feed';
import ProfileUpdate from '../Partials/ProfileUpdate';
import FullScreenLoader from '../Partials/Loaders/FullScreenLoader';
// import { GetMyCardStats } from '../../Services/UserTypeService';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import MyCommittee from '../Partials/MyCommittee';
import { Facebook } from 'react-content-loader';
import EventList from '../Partials/EventList';
import ProfileCard from '../Partials/ProfileCard';
import { CountryGroup } from '../Partials/CountryGroup';
import WelcomeModal from '../Partials/WelcomeModal';

function Profile() {
    const auth = useSelector(state => state.authSlice);

    let { id } = useParams();
    const [userProfile, setProfile] = useState(false);
    const [isMyProfile, changeProfileStatus] = useState(false);
    const [isShowProfilePicture, setShowProfilePicture] = useState(false);
    const [isSeeMoreBiography, setSeeMoreBiography] = useState(false);
    const [isShowCardDetailModal, SetShowCardDetailModal] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {

        GetUserProfile(id, function (resp) {
            if (!resp.status) {
                navigate("/");
            }
            else {
                setProfile(resp.data);
                if (auth?.data.id === resp.data.id) {
                    changeProfileStatus(true);

                }
                else {
                    changeProfileStatus(false);
                }
                document.title = resp.data.firstname;
            }

        });

        window.scrollTo(0, 60);

    }, [id]);

    return (

        <>{userProfile == false ? <FullScreenLoader /> :
            <>
                 <WelcomeModal />
                <Modal show={isShowProfilePicture} onHide={() => setShowProfilePicture(false)}>

                    <Modal.Body>
                        <img src={SecurePicture(userProfile.pictureImage)} className="rounded" alt="Profile Picture" width="100%" />
                        {/* {isMyProfile ? <>
                            <center>
                                <a className='btn btn-primary mt-3'>
                                    <i className='fa fa-edit'></i> Edit Profile Picture
                                </a>
                            </center>


                        </> : ""} */}
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={() => setShowProfilePicture(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>





                <div className='social-container container-fluid' >
                  <div style={{width:"100%"}}>
                  <center>
                <a href='https://angelsweek.org' target='_blank'>  <img src="/assets/images/banner_2024.jpeg" style={{marginBottom:"5px",width:"100%"}} /></a>
                  </center>
                  </div>
                    <div className="profile-main" >
                        <div className="profile-container row" style={{ position: 'relative' }}>
                            {
                                userProfile.id == 320 ? <img src="/assets/images/wbaf-flag.jpg" width="32" className='profile-flag' /> : <img src={userProfile.countryFlag.toLowerCase()} width="32" className='profile-flag' />
                            }
                            <img src={userProfile.masterTitleVariableName == undefined ? `https://id.wbaforum.org/contents/login/img/qualified-entrepreneur.jpg` : GetCardBannerURL(userProfile.masterTitleVariableName)} width="100%" height="200px" />
                            <div className="profile-container-inner  col-sm-12 col-lg-6">
                                <img src={SecurePicture(userProfile.pictureImage,"md")} className="profile-pic" style={{ cursor: 'pointer' }} onClick={() => setShowProfilePicture(true)} />
                                <h1 className="profile-name">{userProfile.firstname}</h1>
                                {
                                    userProfile.id == 320 ? <>
                                        <p className="profile-title">EXECUTIVE CHAIRMAN, WORLD BUSINESS ANGEL INVESTMENT FORUM</p>
                                    </> :
                                        <>
                                            <p className="profile-title">{userProfile.masterTitle}, {userProfile.country}</p>
                                        </>
                                }

                                <div className="socials">

                                    {userProfile.linkedinURL == null ? <></> : <>  <a href={"https://" + userProfile.linkedinURL} target="_blank"><i className="fa-brands fa-linkedin-in"></i></a></>}

                                    {userProfile.instagramURL == null ? <></> : <> | <a href={"https://" + userProfile.instagramURL} target="_blank"><i className="fa-brands fa-instagram"></i></a></>}

                                    {userProfile.facebookURL == null ? <></> : <>|  <a href={"https://" + userProfile.facebookURL} target="_blank"><i className="fa-brands fa-facebook-f"></i></a></>}
                                    {userProfile.twitterURL == null ? <></> : <> | <a href={"https://" + userProfile.twitterURL} target="_blank"><i className="fa-brands fa-twitter"></i></a></>}

                                    {userProfile.youtubeURL == null ? <></> : <>| <a href={"https://" + userProfile.youtubeURL} target="_blank"><i className="fa-brands fa-youtube"></i></a></>}
                                    {userProfile.webSiteURL == null ? <></> : <>| <a href={"https://" + userProfile.webSiteURL} target="_blank"><i className="fa-solid fa-globe"></i></a></>}

                                </div>


                            </div>
                            <div className="groups-list col-sm-12 col-lg-6" style={{ paddingBottom: '10px' }}>
                                {userProfile.id != 320 ? <>


                                    {userProfile?.cards?.map((cardItem, i) =>
                                        <ProfileCard item={cardItem} myProfile={isMyProfile} />

                                    )}
                                </>
                                    : ""}



                            </div>
                        </div>
                        {isMyProfile == true | (isMyProfile == false && userProfile?.biography != null) ? <>  <div className="profile-description row">
                            <div className='col-md-9  p-0'>
                                <h3>About</h3>

                                {/* {userProfile?.biography == null ? <p> Please update your about</p> : ""} */}

                                {
                                    userProfile.biography != null ? <>
                                        {
                                            userProfile.biography?.length > 500 && !isSeeMoreBiography ? <p className='profile-biography'>{userProfile.biography.substring(0, 500) + "..."} </p> : <p className='profile-biography'>{userProfile.biography}</p>
                                        }
                                        {userProfile.biography?.length > 500 && !isSeeMoreBiography ? <a onClick={() => setSeeMoreBiography(true)} className="see-more-link" style={{ cursor: 'pointer' }}>See more...</a> : <>
                                            {userProfile.biography.length > 500 ? <a onClick={() => setSeeMoreBiography(false)} className="see-more-link" style={{ cursor: 'pointer' }}>Hide</a> : ""}
                                        </>}
                                    </> : ""
                                }

                                {isMyProfile ? <ProfileUpdate /> : ""}
                            </div>
                            <div className='col-md-3  p-0' style={{ textAlign: 'right' }}>
                                <EventList />
                            </div>
                        </div></> : ""}
                    </div>


                    <div className="left-sidebar order-lg-1">
                        {auth?.data?.baseCardCount == 0 ? <OtherCardsComponent cardType="10" pageSize="2" /> : ""}
                        {auth?.data?.commiteesCardCount == 0 ? <OtherCardsComponent cardType="30" pageSize="4" /> : <MyCommittee />}
                        <OtherCardsComponent cardType="20" pageSize="3" />
                    </div>

                    <div className="main-content order-lg-2">
                        {isMyProfile ? <CreatePost /> : ""}
                        <Feed userID={id} isMyProfile={isMyProfile} />
                    </div>
                    <div className="right-sidebar order-lg-3">
                        <MyBusinessCard user={userProfile} />
                        {/* {isMyProfile && auth?.data?.businessCardImage != null ? <>

                            <div className="communtiy-box business-card">
                                <h2 className="box-title">MY BUSINESS CARD</h2>
                                <img src={`https://pool.wbaforum.org/images/users/${auth?.data?.businessCardImage}`} className="img-responsive rounded" width="100%" />
                            </div>

                        </> : ""} */}
                        <MyBusinessCardFolder />

                        <CountryGroup />


                        {/* <div className="communtiy-box continental-groups">
                            <h2 className="box-title">CONTINENTAL GROUPS</h2>
                            <div className="continents">
                                <h4 className="single-continent">COMING SOON
                                </h4>
                            </div>
                        </div> */}
                    </div>
                </div>




           
            </>




        }
        
        
        </>

    )


}
export default Profile;