import axios from "axios";
import Cookies from "js-cookie";
import { UrlList } from "./UrlList";

export function GetToken()
{  var cookieValue = Cookies.get('wbafsession');
    if(cookieValue == null | cookieValue == undefined | cookieValue == "")
    {
        window.location.href='https://id.wbaforum.org';
        return 0;
    }
  
   return  Cookies.get('wbafsession');
}
export default async function BaseRequest(url, callback) {


    const config = {
        headers: {
            Authorization: "Bearer "+GetToken()
        }
    };

    await axios.get(`${UrlList.baseURL}/${url}`, config).then(function (resp) {
        callback(resp.data);
    });

}

export async function PostRequest(url, body, callback,isFileContent=false) {

    var  _contentType = 'application/json';
    if(isFileContent == true)
    {
     _contentType =   'multipart/form-data';
    }
    const config = {
        headers: {
            'Content-Type': _contentType,
            Authorization: "Bearer "+GetToken()
        }
    };

    await axios.post(`${UrlList.baseURL}/${url}`, body, config).then(function (resp) {
        callback(resp.data);
    });

}