import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GetCountries, JoinCountryGroup } from "../../Services/CountryService";

export function CountryGroup() {
    const [isShowCountryModal, setShowCountryModal] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [tempList, setTempList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isShowConfirmModal, setShowConfirmModal] = useState(false);
    const [modalSize, setModalSize] = useState("md");
    const auth = useSelector(state => state.authSlice);
    useEffect(() => {
        if (countryList.length == 0 && isShowCountryModal) {
            console.log("Veri çekildi!!!");
            GetCountries((resp) => {
                if (resp.status) {
                    setCountryList(resp.data);
                    setTempList(resp.data);
                }
            });
        }
    }, [isShowCountryModal]);

    const OpenConfirmModal = (item) => {
        setSelectedCountry(item);
        setShowConfirmModal(true);
        setModalSize("lg");
    }

    const JoinGroup = (item) => {
        JoinCountryGroup(selectedCountry.id, (resp) => {
            window.location.reload();
        });
    }

    const searchHandler = (target) => {
        var value = target.value.toLowerCase();
        setTempList(countryList.filter(x => x.countryName.toLowerCase().includes(value)));

    }
    return (
        <>
            <Modal show={isShowCountryModal} onHide={() => setShowCountryModal(false)} size={modalSize} >
                <Modal.Header closeButton >
                    <b>
                        Select Country Group
                    </b>
                </Modal.Header>
                <Modal.Body>
                    {
                        isShowConfirmModal ?
                            <>

                                <div className="row">
                                    <div className="col-4">
                                        <img className="rounded img-thumbnail" src={`https://flagcdn.com/w80/${selectedCountry.code.toLowerCase()}.png`} style={{ width: '100%' }} />
                                    </div>
                                    <div className="col-8">
                                        <h5>{selectedCountry.countryName}</h5>
                                        <span>You can join this group for one year for {selectedCountry.credit} credits.</span>
                                        <br />
                                        <p>
                                            <br /><b>Your Balance:</b> {auth?.data?.cardCredit} Credit
                                        </p>
                                        {
                                            auth.data.cardCredit < selectedCountry.credit ?
                                                <>
                                                    <div className="alert alert-warning">
                                                        <small>You do not have enough credits to join this group. You need to purchase  {selectedCountry.credit} credits.</small>
                                                    </div>
                                                    <a className="btn btn-warning btn-sm" href="https://id.wbaforum.org/myprofile/buycredit" target="_blank"><i className="fa fa-shopping-cart" aria-hidden="true"></i> Buy Credit</a>
                                                </>
                                                : <>

                                                    <a className="btn btn-success btn-sm" onClick={() => JoinGroup()}><i className="fa fa-check" aria-hidden="true"></i> Join Now</a>
                                                </>
                                        }

                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <input type="text" className="form-control" placeholder="Search Country" onChange={(e) => searchHandler(e.target)} />
                                    </div>
                                </div>
                                {
                                    tempList.map((item, index) =>
                                        <div className="country-group-item" key={index}>
                                            <img src={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png`} width="30" style={{ marginRight: '5px' }} />
                                            <div style={{ width: '100%' }}>

                                                <span>
                                                    {item.countryName}
                                                </span>

                                            </div>
                                            <span className="credit">
                                                {item.credit} Credit
                                            </span>

                                            {item.isJoined ? <>
                                                <a className="btn btn-sm btn-success" >Joined</a>
                                            </> :
                                            <>
                                            <a className="btn btn-sm join-link" onClick={() => OpenConfirmModal(item)}>+ Join</a>
                                            </>}
                                        </div>
                                    )
                                }
                            </>

                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        isShowConfirmModal ?
                            <a className="btn btn-sm btn-secondary" onClick={() => { setModalSize("md"); setShowConfirmModal(false) }}><i className="fa fa-chevron-left"></i> Back</a>
                            :
                            <Button variant="secondary" onClick={() => { setShowConfirmModal(false); setModalSize("md"); setShowCountryModal(false) }}>
                                Close
                            </Button>
                    }
                </Modal.Footer>
            </Modal>



            <div className="communtiy-box country-groups" onClick={() => setShowCountryModal(true)} style={{cursor:'pointer'}}>
                <h2 className="box-title">ACCESS TO NEW MARKETS</h2>
               <img src="/assets/images/join-country-groups.jpg" width={"100%"}/>
                {/* <p className="viewall-link"><a href="#">+ JOIN THE GROUP</a></p> */}
            </div>
        </>
    )
}