import { useEffect, useRef, useState } from "react";
import { CreateMessage } from "../../Services/ConversationService";

export default function ConversationNewMessage(props) {
    const notes = useRef();
    const [postData, setPostData] = useState({
        message: null,
        ToUserID: props.toUserId,
    })
    useEffect(()=>{
        const newData = postData;
        newData.ToUserID = props.toUserId;
        setPostData(newData);
    },[props.toUserId]);

    const handleChange = (target) => {
        var value = target.value;
        const newData = postData;
        newData.message = value;
        setPostData(newData);

    }
    const sendMessage = () => {
        CreateMessage(postData, (resp) => {
            if (resp.status) {

                const newData = postData;
                newData.message = "";
                setPostData(newData);

                notes.current.value = "";

                if (props.conversationID == null) {
                    props.loadEvent(resp.data.conversationID);
                }
                else {
                    props.loadEvent(true);
                }
            }
        });
    }


    return (
        <>
            <div className='new-message'>
                <textarea ref={notes} className='form-control' placeholder='Write a message...' rows={4} defaultValue={postData.message} onChange={(e) => handleChange(e.target)}></textarea>
                <a className='btn btn-sm btn-success mt-2' onClick={sendMessage} >Send</a>
            </div>

        </>


    );
}
