import BaseRequest, { PostRequest } from './RequestService';
import { UrlList } from './UrlList';
export async function GetMyNetwork(cardID,countryID,callback) {
    BaseRequest(UrlList.GetMyNetwork+"?cardID="+cardID+"&countryID="+countryID, callback)

}

export async function SearchUser(name,callback) {
    BaseRequest(UrlList.MyNetwork_SearchUser+"?keyword="+name, callback)

}

