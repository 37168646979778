import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SecurePicture from "../../functions/profilepicture";
import { DeleteConversation, GetConversationByID, GetMessages, SearchUser } from "../../Services/ConversationService";
import ConversationNewMessage from "./ConversationNewMessage";

export default function ConversationDetail(props) {
    const messagesRef = useRef();
    const [messages, setMessages] = useState([]);
    const [conversation, setConversation] = useState(null);
    const [isReload, setReload] = useState(false);
    const[isDeleteConfirmModal,setDeleteConfirmModal ] = useState(false);
const navigate = useNavigate();
    useEffect(() => {

        GetConversationByID(props.id, (resp) => {
            setConversation(resp.data);
            LoadData();
        })
    }, [props.id, props.isReload]);

    useEffect(() => {
        if (messages.length > 0) {
            messagesRef.current.scrollTo(0, 6000);
        }


    }, [messages]);

    const LoadData = () => {
        if (props.id != null) {
            GetMessages(props.id, 1, (resp) => {
                if (resp.status) {
                    setMessages(resp.data.messages);
                    // document.getElementsByClassName("messages")[0].scrollIntoView({behavior: "smooth"});

                }
            });
        }
    }

    const refreshEvent = (status) => {
        props.refreshConversationData(true);
        LoadData();
    }

    const handleDelete = () => {
        DeleteConversation(props.id, (resp) => {
            if (resp.status) {
                window.location.href='/Messages';
            }

        });
    }

    const handleClickProfile=(profileID)=>{
navigate("/Profile/"+profileID);
    }

    return (
        <>
            {conversation == null ? "" : <>
            <Modal show={isDeleteConfirmModal} onHide={() => setDeleteConfirmModal(false)}>
                        <Modal.Header closeButton>
                            <b>Are you sure?</b>
                        </Modal.Header>
                        <Modal.Body>
                            <center>
                                <img src="https://img.freepik.com/free-vector/thoughtful-woman-with-laptop-looking-big-question-mark_1150-39362.jpg?w=826&t=st=1671038036~exp=1671038636~hmac=684e9b7c9afe2adb7f7dbfb87fdaace6e70a18847b0ed37d469ad47b5c3de4f2" width="50%" />
                                <h6>
                                Sure you want to delete your entire conversation history with {conversation.otherUser.firstname}?
                                </h6>
                            </center>
                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="danger" onClick={handleDelete}>
                                Yes,Delete
                            </Button>
                            <Button variant="secondary" onClick={() => setDeleteConfirmModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    
                <div className='message-title' >
                <img src={SecurePicture(conversation.otherUser.pictureImage)} className='rounded-circle' width={32} height={32} style={{marginRight:10,cursor:"pointer"}} onClick={()=>handleClickProfile(conversation.otherUser.id)}  />
                    <div className='message-user-name' onClick={()=>handleClickProfile(conversation.otherUser.id)} style={{cursor:"pointer"}}>
                        <span >
                            {conversation.otherUser.firstname}
                        </span>
                     
                        <div className='message-user-title' >
                            {conversation.otherUser.masterTitle}, {conversation.otherUser.country}
                        </div>
                    </div>
                    <div className='message-tools'>

                        <Dropdown align="end">
                            <Dropdown.Toggle variant="default" id="dropdown-basic" >
                                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setDeleteConfirmModal(true)} ><i className="fa fa-trash"></i> Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                </div>

                <div className='messages' ref={messagesRef}>
                    {
                        messages.map((item, i) => <div className='message-item' key={i}>
                            <img src={SecurePicture(item.fromUser.pictureImage,"sm")} className='rounded-circle' />

                            <div className='message-content'>
                                <div className='message-item-header'>
                                    <div className='message-item-header-name'>
                                        {item.fromUser.firstname}  <span> •
                                            {moment.utc(item.date).local().format('hh:mm A')}
                                        </span>
                                    </div>

                                </div>
                                <div className='message-text'>
                                    {item.message}
                                </div>
                            </div>


                        </div>)
                    }



                </div>


                {
                    conversation.otherUser != null ?
                        <ConversationNewMessage conversationID={props.id} toUserId={conversation.otherUser.id} loadEvent={refreshEvent} />
                        : ""
                }

            </>}

        </>


    );
}