import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SecurePicture, { GetPreviewImage } from "../../../functions/profilepicture";
import { CreateComment, DeletePost, GetLikes, LikePost } from "../../../Services/FeedService";
import Comments from "./Comments";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import { Button, Modal } from "react-bootstrap";
import { isContentEditable } from "@testing-library/user-event/dist/utils";
import { useSelector } from "react-redux";

export default function Post(props) {
    const auth = useSelector(state => state.authSlice);
    const likeEl = useRef(null);
    const [isCommentsOpen, setCommentsOpen] = useState(false);
    const [isButtonOpen, setButtonOpen] = useState(false);
    const [isLiked, setLiked] = useState(props.item.isLiked);
    const [newComment, setNewComment] = useState("");
    const [isReadMoreOpen, setReadMore] = useState(false);
    const [isDeleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [isDeleted, setDeleted] = useState(false);
    const [isShowLikeListModal, setShowLikeListModal] = useState(false);
    const [likeList, setLikeList] = useState([]);
    const [isVideoWatching, setVideoWatching] = useState(false);
    const navigate = useNavigate();
    const handleChangeNewComment = (e) => {
        setNewComment(e.value);
        if (e.value.length > 5) {
            setButtonOpen(true);
        }
        else {
            setButtonOpen(false);
        }
    }

    const handleLikeClick = (e) => {
        LikePost(props.item.id, (resp) => {
            setLiked(!isLiked);
            if ((!isLiked) == true) {
                props.item.likeCount += 1;
            }
            else {
                props.item.likeCount -= 1
            }
        });
    }
    const handleCreateComment = () => {
        setCommentsOpen(false);
        CreateComment(props.item.id, newComment, (resp) => {
            if (resp.status) {
                setCommentsOpen(true);
                props.item.commentCount += 1;
            }

        });

    }


    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;

        return text.replace(urlRegex, function (url) {
            var hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }
            // url = url.replace("<pre>","").replace("</pre>","");
            var urlText = url;
            if(url.length > 70)
            {
                urlText = url.substring(0,67) + "...";
            }
            
            return '<a href="' + url + '" target="_blank" class="post-link">' + urlText + '</a>'

        })
        // or alternatively

    }
    function ReadMore() {

    }
    function renderTextContent(text) {
        text = text.replaceAll("\n", "</br>");
        // if (text.length > 200) {
        //     text = "<span style=''>" + text.substring(200) + "...</span>";
        //      text += "{isReadMoreOpen ? <span>"+text+"</span> : ''}";
        //     text += "<a href='#here' onclick='()=>{setReadMore(true)}'><b>Read more</b> </a>";
        // }

        return text;
        // or alternatively

    }
    const deleteHandle = () => {
        DeletePost(props.item.id, (resp) => {
            if (resp.status) {
                setDeleted(true);
                setDeleteConfirmModal(false);
            }
        });
    }
    const showLikeModal = () => {
        setShowLikeListModal(true);
        if (likeList.length == 0) {
            GetLikes(props.item.id, (resp) => {
                setLikeList(resp.data);
            });
        }

    }
    const showProfile = (id) => {
        navigate(`/Profile/` + id);
    }

    const GetYoutubeVID = (videoURL) => {
        return videoURL.replaceAll("http://", "").replace("https://", "").replace("www.youtube.com/watch?v=", "").replace("youtu.be/", "");
    }
    return (


        <>
            {!isDeleted ? <>
                <div className="post">
                    <Modal show={isDeleteConfirmModal} onHide={() => setDeleteConfirmModal(false)}>
                        <Modal.Header closeButton>
                            <b>Are you sure?</b>
                        </Modal.Header>
                        <Modal.Body>
                            <center>
                                <img src="https://img.freepik.com/free-vector/thoughtful-woman-with-laptop-looking-big-question-mark_1150-39362.jpg?w=826&t=st=1671038036~exp=1671038636~hmac=684e9b7c9afe2adb7f7dbfb87fdaace6e70a18847b0ed37d469ad47b5c3de4f2" width="50%" />
                                <h6>
                                    This post will be deleted, are you sure?
                                </h6>
                            </center>
                        </Modal.Body>
                        <Modal.Footer>

                            <Button variant="danger" onClick={deleteHandle}>
                                Yes,Delete
                            </Button>
                            <Button variant="secondary" onClick={() => setDeleteConfirmModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={isShowLikeListModal} onHide={() => setShowLikeListModal(false)}>
                        <Modal.Header closeButton>
                            <b>Likes</b>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    {likeList.map((item, i) => <>
                                        <div key={i} className="list-user-item" onClick={() => showProfile(item.user.id)}>
                                            <img src={SecurePicture(item.user.pictureImage,"sm")} width={32} height={32} className="rounded-circle" />
                                            <div className="list-user-name-wrapper">
                                                <div className="list-user-name">
                                                    {item.user.firstname}
                                                </div>
                                                <div className="list-user-title">
                                                    {item.user.masterTitle}, {item.user.country}
                                                </div>
                                            </div>
                                        </div>

                                    </>)}
                                </div>
                            </div>
                        </Modal.Body>

                    </Modal>

                    <div className="post-author">
                        <Link to={"/Profile/" + props.item.user.id}><img src={SecurePicture(props.item.user.pictureImage,"sm")} alt="User Photo" /></Link>
                        <div>
                            <Link to={"/Profile/" + props.item.user.id}>
                                <h1 style={{ padding: 0, marginBottom: 0 }}>{props.item.user.firstname}</h1>
                            </Link>
                            <p className="author-title" style={{ margin: 0 }}>{props.item.user.masterTitle}, {props.item.user.country}
                                <div>
                                    <small className="post-time" title={props.item.date + " UTC"} style={{ margin: 0 }}>
                                        {moment.utc(props.item.date).local().startOf('seconds').fromNow()}
                                    </small>
                                </div>

                            </p>

                        </div>
                        {
                            props.item.isMyPost ?
                                <>
                                    <div style={{ flex: '9', textAlign: 'right' }}>
                                        <Dropdown align="end">
                                            <Dropdown.Toggle variant="default" id="dropdown-basic" >
                                                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1" onClick={() => setDeleteConfirmModal(true)} ><i className="fa fa-trash"></i> Delete Post</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>
                                : ""
                        }

                    </div>

                    <p dangerouslySetInnerHTML={{ __html: renderTextContent(urlify(props.item.text == null ? "" : props.item.text)) }}>
                    </p>

                    {props.item.mediaImage != null ? <img src={"https://pool.wbaforum.org/images/posts/800/" + props.item.mediaImage} className="rounded" alt="Posted Image" width="100%" /> : ""}

                    {props.item.preview_Url != null ?
                        <>
                            {
                                props.item.preview_Url.includes("youtube.com") | props.item.preview_Url.includes("youtu.be") ?
                                    //youtube ise otomatik izleme işlemi için gerekli blok.
                                    <>
                                        <div className="post-preview-wrapper" style={{ width: '100%' }}>
                                            {
                                                isVideoWatching == false ? <>
                                                    <div className="post-preview-image">
                                                        <a onClick={() => setVideoWatching(true)} target="_blank" style={{cursor:'pointer'}}> <img src={GetPreviewImage(props.item.preview_Image)} alt="Preview Thumbnail" /></a>
                                                    </div>
                                                </> : <>
                                                    <div className="post-preview-image">
                                                        <iframe width="100%" height="450" src={`https://www.youtube.com/embed/${GetYoutubeVID(props.item.preview_Url)}?autoplay=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                              
                                                    </div>
                                                </>
                                            }


                                            <div className="post-preview-description">
                                                <div className="post-preview-title">
                                                    <a href={props.item.preview_Url} target="_blank">
                                                        {props.item.preview_Title}
                                                    </a>
                                                </div>
                                                <div className="post-preview-url">
                                                    <a href={props.item.preview_Url} target="_blank"> {props.item.preview_Url}</a>
                                                </div>
                                            </div>
                                        </div>

                                    </>

                                    :

                                    <>
                                        <div className="post-preview-wrapper" style={{ width: '100%' }}>
                                            <div className="post-preview-image">
                                                <a href={props.item.preview_Url} target="_blank"> <img src={GetPreviewImage(props.item.preview_Image)} alt="Preview Thumbnail" /></a>
                                            </div>

                                            <div className="post-preview-description">
                                                <div className="post-preview-title">
                                                    <a href={props.item.preview_Url} target="_blank">
                                                        {props.item.preview_Title}
                                                    </a>
                                                </div>
                                                <div className="post-preview-url">
                                                    <a href={props.item.preview_Url} target="_blank"> {props.item.preview_Url}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                            }



                        </>
                        : ""}

                    {
                        props.item.pdfName != null ?
                            <>
                                <a href={`https://api-network.wbaforum.org/upload/posts/pdf/o/` + props.item.pdf} className="post-pdf-wrapper" target="_blank" download={props.item.pdfName}>

                                    {/* <div style={{flex:1}}>
                                      
                                    </div> */}
                                    <div style={{ flex: 8 }}>
                                        <div className="post-pdf-title">  <i className="fa fa-file-pdf-o" aria-hidden="true"></i> {props.item.pdfName.replace(".pdf", "")} </div>
                                        <div className="post-pdf-size">
                                            {props.item.pdfSize} KB
                                        </div>


                                    </div>
                                    <div style={{ flex: 1, textAlign: "right" }}>
                                        <i className="fa fa-cloud-download" style={{ fontSize: "20px" }} aria-hidden="true"></i>
                                    </div>

                                </a>
                            </>
                            : ""
                    }
                    <div className="post-stats">
                        <div>
                            <img src="/assets/images/thumbsup.png" alt="Thumbsup" />
                            {/* <img src="/assets/images/love.png" alt="Love" />
                    <img src="/assets/images/clap.png" alt="Clab" /> */}
                            {false ?
                                <> <span className="liked-users" onClick={() => showLikeModal()} style={{ cursor: 'pointer' }}> {props.item.likeCount - 1} others</span></>
                                :
                                <> <span className="liked-users" onClick={() => showLikeModal()} style={{ cursor: 'pointer' }}>{props.item.likeCount} {props.item.likeCount > 1 ? "Likes" : "Like"}</span></>
                            }
                        </div>
                        <div onClick={() => { setCommentsOpen(!isCommentsOpen) }}>
                            <span>{props.item.commentCount} comments </span>
                            {/* &#8729; 20 shares */}
                        </div>
                    </div>
                    <div className="post-activity">
                        {/* <div>
                    <img src="/assets/images/member-02.jpg" className="post-activity-user-icon" />
                    <img src="/assets/images/down-arrow.png" className="post-activity-arrow-icon" />
                </div> */}
                        {/* <div className="post-activity-link">
                    <img src="/assets/images/share.png" />
            <span>Share</span>
                </div>
                <div className="post-activity-link">
                    <img src="/assets/images/send.png" />
            <span>Send</span>
                </div> */}
                        <div className="post-activity-link">
                            <a className={isLiked ? "likeLink like-success" : "likeLink"} ref={likeEl} style={{ cursor: 'pointer' }} onClick={(e) => handleLikeClick(e)}>
                                <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                                <span style={{ marginLeft: 5 }}>Like</span>
                            </a>
                        </div>
                        <div className="post-activity-link" >
                            <a className="likeLink" style={{ cursor: 'pointer' }} onClick={() => { setCommentsOpen(!isCommentsOpen) }}>
                                <i className="fa fa-comments" aria-hidden="true"></i>
                                <span style={{ marginLeft: 5 }}> Comment</span>
                            </a>
                        </div>

                    </div>
                    {isCommentsOpen ?

                        <div className="post-comments">
                            <div className="post-comment-create-wrapper">
                                <div className="post-comment-item">
                                    <div className="post-comment-image">
                                        <img src={SecurePicture(auth?.data.pictureImage,"sm")} width="48" className="rounded-circle" />
                                    </div>
                                    <div className="comment-text">
                                        <textarea className="form-control" placeholder="Send a comment" onChange={(e) => handleChangeNewComment(e.target)}></textarea>
                                        {isButtonOpen ? <a className="btn btn-sm btn-warning pull-right mt-2" style={{ float: 'right' }} onClick={() => handleCreateComment()}>Publish</a> : ""}

                                    </div>

                                </div>

                            </div>
                            <Comments postID={props.item.id} commentCount={props.item.commentCount} />
                        </div> : ""}
                </div>
            </>

                : ""}

        </>


    )
}