import BaseRequest, { PostRequest } from './RequestService';
import { UrlList } from './UrlList';
export async function GetMyBusinessCardFolder(pageSize,callback) {
    BaseRequest(UrlList.GetMyBusinessCardFolder+"?pageSize="+pageSize, callback)

}

export async function CreateRequest(userID,description,callback) {
    PostRequest(UrlList.CreateRequest,{userID,description}, callback)

}
export async function GetPendingRequests(direction,callback) {
    BaseRequest(UrlList.GetPendingRequest+"?direction="+direction, callback)

}

export async function ChangeStatus(requestID,status,callback) {
    PostRequest(UrlList.ChangeStatus,{requestID,status}, callback)

}

export async function IsSavedBusinessCard(userID,callback) {
    BaseRequest(UrlList.IsSavedBusinessCard+"?userID="+userID, callback)

}
