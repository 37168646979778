import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import SecurePicture from '../../functions/profilepicture';
import { GetMyBusinessCardFolder } from '../../Services/BusinessCardService';
import { GetUserTypes } from '../../Services/UserTypeService';
import CardTypeComponent from '../Layout/CardTypeComponent';

function Cards() {

    let cardCategoryID = useParams("id");
    const auth = useSelector(x => x.authSlice);
    const [cardTypes, setCardTypes] = useState([]);
    useEffect(() => {
        GetUserTypes(cardCategoryID.id,100,false,(resp) => { setCardTypes(resp.data);  });
    }, []);
    return (
        <>

            <div className="business-card-container row">
                <div className="col-md-12"><br></br>
                    <h4 >Cards</h4>

                    <div className='row'>
                       {cardTypes.map((item,i)=><div key={i} className="col-4">
                       <CardTypeComponent key={i} item={item} />

                       </div>)}

                    </div>

                    <div className="clearfix"></div>

                </div></div>
        </>

    );
}

export default Cards;