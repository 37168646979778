import { configureStore } from "@reduxjs/toolkit";
import notification from "./notificationSlice";

import authSlice from "./auth";
import generalSlice from "./generalSlice";

const store = configureStore({
    reducer: {
        authSlice,
        notification,
        generalSlice,
    }

});

export default store;