import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getFileSize } from "../../functions/fileHelpers";
import SecurePicture, { GetCardNamedCardURL, GetCardBannerURL } from "../../functions/profilepicture";
import { CreateNewPost, GetPreview } from "../../Services/FeedService";
import { GetMyCards } from "../../Services/UserProfileService";

export default function CreatePost() {
    const preview = useRef(null);
    const auth = useSelector(state => state.authSlice);
    const [isShowSendButton, setShowSendButton] = useState(false);
    const [isShowImageModal, setShowImageModal] = useState(false);
    const [file, setFile] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [isPreviewLoading, setPreviewLoading] = useState(false);
    const [isShowCelebrationModal, setShowCelebrationModal] = useState(false);
    const [isShowVideoModal, setShowVideoModal] = useState(false);
    const [celebrationType, setCelebrationType] = useState(1);
    const [isShowCreatePostModal, setShowCreatePostModal] = useState(false);
    // const [pdfFileName, setPdfFileName] = useState("");
    const [isSectionsDisabled, setSectionDisable] = useState(false);
    const [isShowStateModal, setShowStateModal] = useState(false);
    const [myCards, setMyCardList] = useState([]);
    const [isPostReady, setPostReadyStatus] = useState(false);
    const [postState, setPostState] = useState({
        stateID: null,
        stateText: "Anyone",
        image: null
    });
    const [previewURL, setPreviewURL] = useState({
        title: "",
        url: "",
        image: "",
        isLoading: false,
        isLoaded: false,
    });
    const [isLoading, setLoading] = useState(false);
    const [postData, setPostData] = useState({
        text: "",
        image: "",
        pdf: "",
        pdfName: "",
        pdfSize: 0,
        postType: 1,
        stateID: null

    });


    useEffect(() => {
        GetMyCards((resp) => {
            setMyCardList(resp.data);
        });



    }, []);


    const _getPreview = (text) => {

        if (previewURL.isLoaded) {

        }
        else {

            var textCopy = text + "";
            if (textCopy.includes("<pre>")) {
                const result = textCopy.match(/<pre>(.*?)<\/pre>/g);
                if (result != null) {
                    var url = result[0].replace(/<\/?pre>/g, '').replace(' ', "").replace(" ", "");


                    GetPreview(url, (resp) => {
                        setPreviewURL({
                            isLoaded: true,
                            isLoading: false,
                            image: resp.image,
                            title: resp.title,
                            url
                        });

                        const newData = { ...postData }
                        newData.previewUrl = url;
                        newData.previewImage = resp.image
                        newData.previewTitle = resp.title;
                        newData.text = text.replace(/<\/?pre>/g, '');
                        setPostData(newData);
                        setSectionDisable(true);
                    });



                    setPreviewURL({
                        isLoaded: false,
                        isLoading: true,
                        url: url
                    });
                }
                setPreviewLoading(true);
            }
            else if (isPreviewLoading) {
                setPreviewLoading(false);

            }

        }





    }
    const textareaResize = () => {
        const tx = document.getElementsByTagName("textarea");
        for (let i = 0; i < tx.length; i++) {
            tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
            // if (tx[i].scrollHeight >=300) {
            //     tx[i].setAttribute("style", "height:300px;overflow-y:auto;");
            // }
            // else {
            //     tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
            // }

            tx[i].addEventListener("input", OnInput, false);
        }
    }

    function OnInput() {
        this.style.height = 0;
        this.style.height = (this.scrollHeight) + "px";
    }

    function handlePostChange(e) {


        const latest = urlify(e.target.value);

        const newData = { ...postData }
        newData.text = latest.replace(/<\/?pre>/g, '');
        setPostData(newData);
        _getPreview(latest);



        if (e.target.value.length > 1) {
            setPostReadyStatus(true);
        }
        else {
            setPostReadyStatus(false);
        }

        textareaResize();






    }

    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;

        return text.replace(urlRegex, function (url) {
            var hyperlink = url;
            if (!hyperlink.match('^https?:\/\/')) {
                hyperlink = 'http://' + hyperlink;
            }
            return '<pre>' + url + '</pre>'

        })
        // or alternatively

    }

    const handlePost = () => {
        setLoading(true);

     if(postData.text == "" && (postData.image == null | postData.image == "") && postData.pdfName == "")
     {
        setLoading(false);
        setPostReadyStatus(false);
     }
     else  {
        CreateNewPost(postData, (resp) => {
            if (resp.status) {
                setLoading(false);
                window.location.reload();
            }

        });
     }
    }
    const handleClose = () => setShowImageModal(false);
    const handleCreatePostModal = () => {
        setShowCreatePostModal(false);
        setTimeout(() => {
            setShowCelebrationModal(false);
            setShowVideoModal(false);

        }, 250);

    }
    const handleChangeFile = (e) => {
        setFile(e.target.files[0]);
        setImageURL(URL.createObjectURL(e.target.files[0]));


        const newData = postData;
        newData.image = e.target.files[0];
        setPostData(newData);
        setSectionDisable(true);
        setPostReadyStatus(true);
    }

    const handleChangePdfFile = (e) => {
        // setFile(e.target.files[0]);
        // setImageURL(URL.createObjectURL(e.target.files[0]));


        var size = getFileSize(e.target.files[0]);
        if (size > 5120) {
            alert("Files larger than 5 MB cannot be uploaded.");
        }
        else {
            // setPdfFileName(e.target.files[0].name);
            const newData = postData;
            newData.pdfName = e.target.files[0].name;
            newData.pdf = e.target.files[0];
            newData.pdfSize = size;
            setPostData(newData);
            setSectionDisable(true);
            setPostReadyStatus(true);
        }

    }
    const handleUsePhoto = () => {
        const newData = postData;
        newData.image = file;
        setPostData(newData);
        handleClose();
        setShowCreatePostModal(true);
        setPostReadyStatus(true);
    }

    const removePreview = () => {
        setPreviewURL({
            isLoaded: false,
            isLoading: false,
            image: null,
            title: null,
            url: null
        });


        const newData = { ...postData }
        newData.previewUrl = null;
        newData.previewImage = null;
        newData.previewTitle = null;
        setPostData(newData);
        setSectionDisable(false);
        
    }

    const removeImage = () => {


        const newData = { ...postData }
        newData.image = "";
        setPostData(newData);
        setFile("");
        setImageURL("");
        setSectionDisable(false);
    }

    const removePdfFile = () => {


        const newData = { ...postData }
        newData.pdffile = "";
        newData.pdfName = "";
        setPostData(newData);
        // setPdfFileName("");
        setSectionDisable(false);
    }

    const selectPostType = (id) => {
        setCelebrationType(id);
        setShowCelebrationModal(false);
        const newData = { ...postData }
        newData.postType = id;
        setPostData(newData);
    }

    const selectPostState = (props) => {
        setPostState(props);
        setShowStateModal(false);
        const newData = { ...postData }
        newData.stateID = props.stateID;
        setPostData(newData);

    }
    const handlerChangeVideo = (target) => {
        if (target.value == null | target.value == "") {
            return;
        }
        target.value = target.value.replace("www.", "");
        if (target.value.includes("https://youtube.com/")) {
            const latest = urlify(target.value);
            // const newData = postData;
            // newData.text += latest;
            _getPreview(latest);
            setShowVideoModal(false);
            setShowCreatePostModal(true);
        }
    }
    const cancelVideo = () => {
        setShowVideoModal(false);
        setShowCreatePostModal(true);
        setShowStateModal(false);
    }

    const cancelCelebration = () => {
        setShowCelebrationModal(false);
        setShowCreatePostModal(true);
        setShowStateModal(false);
    }
    return (
        <>
            <div style={{ position: 'relative', width: '100%' }}>

                <Modal show={isShowCreatePostModal} onHide={handleCreatePostModal} size="lg" className="create-post-modal">
                    <Modal.Header closeButton>
                        <h6 className="modal-title">
                            Create a post
                        </h6>
                    </Modal.Header>

                    {
                        isShowCelebrationModal == true ?

                            <Modal.Body className="create-post-modal-body">
                                <div className="col-12">
                                    <div className="post-type-selection-wrapper">
                                        <div className={celebrationType == 1 ? "post-type-selection-item selected" : "post-type-selection-item"} onClick={() => selectPostType(1)}>
                                            No Celebration
                                        </div>
                                        <div className={celebrationType == 2 ? "post-type-selection-item selected" : "post-type-selection-item"} onClick={() => selectPostType(2)}>
                                            <i className="fa fa-birthday-cake" aria-hidden="true"></i> Birthday
                                        </div>
                                        <div className={celebrationType == 3 ? "post-type-selection-item selected" : "post-type-selection-item"} onClick={() => selectPostType(3)}>
                                            <i className="fa fa-flag" aria-hidden="true"></i> National Day
                                        </div>
                                    </div>
                                    <a className="btn btn-sm btn-secondary mt-2" onClick={() => { cancelCelebration() }}>
                                        <i className="fa fa-chevron-left" aria-hidden="true"></i> Back
                                    </a>
                                </div>
                                <div className="clearfix"></div>
                            </Modal.Body>
                            :
                            isShowVideoModal == true ? <Modal.Body className="create-post-modal-body">
                                Please Enter Youtube URL:
                                <input type="text" className="form-control" onChange={(e) => handlerChangeVideo(e.target)} />
                                <div className="clearfix"></div>
                                <a className="btn btn-sm btn-secondary mt-2" onClick={() => { cancelVideo() }}>
                                    <i className="fa fa-chevron-left" aria-hidden="true"></i> Back
                                </a>
                            </Modal.Body>
                                :

                                isShowStateModal == true ?

                                    <Modal.Body className="create-post-modal-body">
                                        <div className="col-12">
                                            <div className="post-state-selection-wrapper">
                                                <div className={postState == null ? "post-state-selection-item selected" : "post-state-selection-item"} onClick={() => selectPostState({ stateID: null, stateText: "Anyone", image: null })}>
                                                    <i className="fa fa-globe" aria-hidden="true"></i>  Anyone
                                                </div>
                                                {myCards.map((item, i) => <>
                                                    <div className={postState == item.userType.id ? "post-state-selection-item selected" : "post-state-selection-item"} style={{ backgroundImage: GetCardBannerURL(item.userType.variableName) }} onClick={() => selectPostState({ stateID: item.userType.id, stateText: item.userType.typeName, image: item.userType.variableName })}>
                                                        <img src={GetCardNamedCardURL(item.userType.variableName)} width="40" /> {item.userType.typeName}
                                                    </div>
                                                </>)}
                                                {/* <div className={celebrationType == 2 ? "post-state-selection-item selected" : "post-state-selection-item"} onClick={() => selectPostType(2)}>
                                                    <i className="fa fa-birthday-cake" aria-hidden="true"></i> Birthday
                                                </div>
                                                <div className={celebrationType == 3 ? "post-state-selection-item selected" : "post-state-selection-item"} onClick={() => selectPostType(3)}>
                                                    <i className="fa fa-flag" aria-hidden="true"></i> National Day
                                                </div> */}

                                            </div>
                                            <a className="btn btn-sm btn-secondary mt-2" onClick={() => { cancelVideo() }}>
                                                <i className="fa fa-chevron-left" aria-hidden="true"></i> Back
                                            </a>
                                        </div>
                                        <div className="clearfix"></div>
                                    </Modal.Body>
                                    :
                                    <Modal.Body className="create-post-modal-body" style={{ position: 'relative' }}>
                                        <div className="create-post-network-wrapper">
                                            <div className="user-image">
                                                <img src={SecurePicture(auth?.data.pictureImage,"sm")} className="rounded-circle" width="38" />
                                            </div>
                                            <div className="user-name">
                                                {auth?.data.firstname}
                                            </div>
                                            <div className="post-scope" onClick={() => setShowStateModal(true)}>
                                                {postState.image == null ? <i className="fa fa-globe" aria-hidden="true"></i> : <img src={GetCardNamedCardURL(postState.image)} width="23" className="rounded" />} {postState.stateText} <i className="fa fa-angle-down" aria-hidden="true"></i>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea className="new-post-input" id="post-text" placeholder="What do you want to talk about?" rows={4} onChange={(e) => handlePostChange(e)} defaultValue={postData.text}></textarea>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="preview-area">
                                                {previewURL.isLoading ? <div className="url-area" style={{ padding: "5px" }}>
                                                    <b>Preview is loading...</b>
                                                </div> : ""}
                                                {previewURL.isLoaded ? <>
                                                    <div className="col-md-12">
                                                        <div className="post-preview-wrapper">
                                                            <div className="post-preview-remove-button">
                                                                <i className="fa fa-times-circle" aria-hidden="true" title="Remove Preview" onClick={removePreview}></i>
                                                            </div>
                                                            <div className="post-preview-image">

                                                                <img src={previewURL.image} />

                                                            </div>

                                                            <div className="post-preview-description">
                                                                <div className="post-preview-title">
                                                                    {previewURL.title}
                                                                </div>
                                                                <div className="post-preview-url">
                                                                    {previewURL.url}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </> : ""}
                                                <div className="image-area">
                                                    {postData.image != "" ?
                                                        <>

                                                            <div className="post-preview-remove-button">
                                                                <i className="fa fa-times-circle" aria-hidden="true" title="Remove Preview" onClick={removeImage}></i>
                                                            </div>
                                                            <center><img id="preview" src={imageURL} width="100%" className="rounded" /> </center> </> : ""}
                                                </div>
                                            </div>

                                        </div>


                                    </Modal.Body>

                    }
                    {
                        isShowCelebrationModal == true | isShowVideoModal == true | isShowStateModal == true ? ""
                            :
                            <>
                                {
                                    postData.pdfName != "" ?
                                        <Modal.Footer className="pdf-file-wrapper">

                                            <div className="" style={{ fontSize: '14px' }}>
                                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>  <span style={{ fontSize: '13px' }}>{postData.pdfName}</span>
                                            </div>
                                            <div  >
                                                <i className="fa fa-trash remove-button" title="Remove PDF File" onClick={removePdfFile}></i>
                                            </div>

                                        </Modal.Footer>
                                        : ""
                                }
                                <Modal.Footer>

                                    <div className="left-footer">
                                        <div className={isSectionsDisabled ? "share-section disabled" : "share-section"} title="Upload Images">
                                            <label for="file-upload" className="custom-file-upload">
                                                <i className="fa fa-picture-o" aria-hidden="true"></i>
                                            </label>
                                            <input id="file-upload" type="file" onChange={(e) => handleChangeFile(e)} accept=".jpg,.jpeg,.png" />


                                        </div>

                                        <div className={isSectionsDisabled ? "share-section disabled" : "share-section"} title="Upload PDF File" onClick={() => setShowVideoModal(true)} style={{ cursor: 'pointer' }} >

                                            <i className="fa fa-youtube" aria-hidden="true" ></i>



                                        </div>
                                        <div className={isSectionsDisabled ? "share-section disabled" : "share-section"} title="Upload PDF File">
                                            <label for="pdf-upload" className="custom-file-upload">
                                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                            </label>
                                            <input id="pdf-upload" type="file" onChange={(e) => handleChangePdfFile(e)} accept=".pdf" />


                                        </div>


                                        {/* <div className="share-section">

                                            <i className="fa fa-youtube-square" aria-hidden="true"></i>




                                        </div> */}

                                        <div className="celebration-selection  " onClick={() => setShowCelebrationModal(true)}>
                                            {
                                                celebrationType == 1 ?
                                                    <><i className="fa fa-info-circle"></i> No Celebration </>
                                                    : celebrationType == 2 ?
                                                        <><i className="fa fa-birthday-cake" aria-hidden="true"></i> Birthday</>
                                                        :
                                                        <> <i className="fa fa-flag" aria-hidden="true"></i>  National Day</>
                                            }
                                        </div>
                                    </div>

                                    {isLoading ? <>
                                        <Button variant="success" disabled onClick={() => handlePost()}>
                                            <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                                            Post
                                        </Button>

                                    </> : <Button variant="success" disabled={!isPostReady} onClick={() => handlePost()}>
                                        Post
                                    </Button>
                                    }
                                </Modal.Footer>
                            </>
                    }
                </Modal>


                <Modal show={isShowImageModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        {imageURL != "" ?
                            <img id="preview" src={imageURL} width="100%" className="rounded" />
                            :
                            <center>
                                <label for="file-upload" className="custom-file-upload">
                                    <i className="fa fa-cloud-upload"></i> Select a Photo
                                </label>
                                <input id="file-upload" type="file" onChange={(e) => handleChangeFile(e)} />
                            </center>
                        }


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleUsePhoto} >
                            Done
                        </Button>
                    </Modal.Footer>
                </Modal>



                {/* <Modal show={isShowVideoModal} onHide={() => setShowVideoModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Video</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Please Enter Youtube URL:
                        <input type="text" className="form-control" onChange={(e) => handlerChangeVideo(e.target)} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowVideoModal(false)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal> */}



                <div className="create-post" onClick={() => setShowCreatePostModal(true)}>
                    <div className="create-post-input">
                        <img src={SecurePicture(auth.data.pictureImage)} />
                        <textarea name="" id="text" rows="2" placeholder="Start a post" readOnly style={{ cursor: 'pointer' }} ></textarea>

                    </div>


                    <div className="create-post-links">
                        <li><img src="/assets/images/icons-14.png" for="file-upload" />Photos</li>
                        <li><img src="/assets/images/icons-15.png" />Video</li>
                        {/* <li><img src="/assets/images/icons-16.png" />Documents</li> */}
                        <li><img src="/assets/images/icons-17.png" />PDF Document</li>
                        <li>
                            {/* {isShowSendButton ? <a className="btn  btn-warning" onClick={() => handlePost()} >Send Post</a> : ""} */}
                        </li>
                    </div>



                </div>
            </div>
        </>




    );
}