import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
name: 'total',
initialState:{count:2},
reducers:{
    refreshCount: (state,action) => {
        state.count = action.payload
    }
}

});

export const {refreshCount} = notificationSlice.actions

export default notificationSlice.reducer 