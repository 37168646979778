import React from 'react'
import ContentLoader from 'react-content-loader'

const CommentListLoader = props => {
  return (
    <ContentLoader 
    speed={2}
    width={400}
    height={160}
    viewBox="0 0 400 160"
    backgroundColor="#d4d4d4"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" /> 
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" /> 
    <circle cx="20" cy="20" r="20" /> 
    <rect x="47" y="60" rx="3" ry="3" width="88" height="6" /> 
    <rect x="47" y="78" rx="3" ry="3" width="52" height="6" /> 
    <circle cx="20" cy="72" r="20" /> 
    <rect x="49" y="111" rx="3" ry="3" width="88" height="6" /> 
    <rect x="49" y="129" rx="3" ry="3" width="52" height="6" /> 
    <circle cx="20" cy="123" r="20" />
  </ContentLoader>
  )
}

CommentListLoader.metadata = {
  name: 'Rituraj ratan',
  github: 'riturajratan',
  description: 'Listing with thumbnail',
  filename: 'ListingWithThumbnail',
}

export default CommentListLoader