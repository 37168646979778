import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ComingSoon from '../Partials/ComingSoon';
import '../../message.css';
import MyBusinessCardFolder from '../Partials/MyBusinessCardFolder';
import { GetConversations } from '../../Services/ConversationService';
import SecurePicture from '../../functions/profilepicture';
import { NavLink } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import ConversationDetail from '../Partials/ConversationDetail';
import ConversationNewMessage from '../Partials/ConversationNewMessage';
import NewConversation from '../Partials/NewConversation';
import moment from 'moment';
import { markSeenMessages } from '../../stores/auth';
function Messages() {

    const auth = useSelector(state => state.authSlice);
    const [conversations, setConversation] = useState([]);
    const [searchConversations, setSearchConversation] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [selectedConversationId, setConversationId] = useState(null);
    const [isNewConversationOpen, setNewConversation] = useState(false);
    const [newConversationUserID, setNewUserID] = useState(null);
    let { id } = useParams();
    const dispatch = useDispatch();
    var conversations_temp = conversations;
    useEffect(() => {
        refreshConversationData();
        dispatch(markSeenMessages());

    }, []);

    useEffect(() => {

    }, [id]);

    const OpenConversation = (id, item) => {
        setConversationId(id);
        setSelectedConversation(item);
        setNewConversation(false);
    }

    const handleNewConversation = () => {
        setNewConversation(true);
        setSelectedConversation([]);
        setConversationId(null);
    }

    const refreshConversationData = (searchKeyword) => {
        GetConversations((resp) => {
            if (resp.status) {
                setConversation(resp.data);

            }
        });
    }

    const handleClickUserItem = (id) => {
        var newData = conversations;
        newData.filter(x => x.id == id)[0].isUnread = true;
        setConversation(newData);
    }

    const handleSearchMessages = (target) => {


    }
    return (

        <div className="social-container container-fluid">
            {true == true ? <>
                <div className='message-wrapper'>
                    <div className='message-user-wrapper'>
                        <div className='user-panel-header'>
                            <div className='user-panel-header-title'>
                                MESSAGING
                            </div>
                            <div className='user-panel-header-tools'>
                                <Link to="/Messages/new"><i className="fa fa-pencil-square-o" aria-hidden="true" style={{ cursor: 'pointer' }} ></i></Link>
                            </div>
                        </div>
                        <div className='user-panel-body'>
                            {/* <div className='message-search has-search'>
                                <i className="fa fa-search form-control-feedback"></i>
                                <input type="text" placeholder='Search in messages' onChange={(e) => handleSearchMessages(e.target)} />
                            </div> */}
                            <div className='user-list'>
                             {searchConversations.length == 0 ? 
                            <>
                               {
                                    conversations.map((item, i) => <>

                                        <Link to={`/Messages/` + item.id} onClick={() => handleClickUserItem(item.id)} key={i} >
                                            <div className={item.id == id ? 'user-item active' : item.isUnread == false ? 'user-item unread' : 'user-item'}>
                                                <img src={SecurePicture(item.otherUser.pictureImage,"sm")} className='rounded-circle' />
                                                <div className='user-item-name'>
                                                    <span>{item.otherUser.firstname}</span>
                                                    <div><small>{item.lastMessage}...</small></div>
                                                </div>
                                                <div className='user-item-date'>
                                                    {moment.utc(item.lastMessageDate).local().format("DD MMM")}
                                                </div>

                                            </div>
                                        </Link>

                                    </>)
                                }
                            
                            </> :
                            <>
                               {
                                    searchConversations.map((item, i) => <>

                                        <Link to={`/Messages/` + item.id} onClick={() => handleClickUserItem(item.id)} key={i} >
                                            <div className={item.id == id ? 'user-item active' : item.isUnread == false ? 'user-item unread' : 'user-item'}>
                                                <img src={SecurePicture(item.otherUser.pictureImage,"sm")} className='rounded-circle' />
                                                <div className='user-item-name'>
                                                    <span>{item.otherUser.firstname}</span>
                                                    <div><small>{item.lastMessage}...</small></div>
                                                </div>
                                                <div className='user-item-date'>
                                                    {moment.utc(item.lastMessageDate).local().format("DD MMM")}
                                                </div>

                                            </div>
                                        </Link>

                                    </>)
                                }
                                </>
                            
                            
                            }
                            </div>
                        </div>
                    </div>
                    <div className='message-content-wrapper'>
                        {id?.toLowerCase() == 'new' ? <NewConversation refreshConversationData={refreshConversationData} /> : ""}
                        {id != null && id != 'new' ? <ConversationDetail id={id} refreshConversationData={refreshConversationData} /> : ""}
                    </div>
                    <div className='message-right-panel'>
                        <MyBusinessCardFolder />
                    </div>
                </div>

            </> : <b>Coming Soon</b>}
        </div>

    );
}

export default Messages;