import { useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RemoveCard } from "../../Services/UserProfileService";

export default function ProfileCard(props) {
    const [isShowCardDetailModal, SetShowCardDetailModal] = useState(false);
    const[isShowError, setShowError] = useState(false);
    const auth = useSelector(state => state.authSlice);
    const ConfirmRemove = () => {
        RemoveCard(props.item.id, (resp) => {

            if (resp?.status) {
                window.location.reload();
            }
            else {
                setShowError(true);
            }
        });
    }

    useEffect(()=>{
        console.log(props.item);
    },[])
    return (
        <>
            <Modal show={isShowCardDetailModal} onHide={() => SetShowCardDetailModal(false)}>

                <Modal.Body>

                    <center>
                        <img src='https://img.freepik.com/free-vector/thoughtful-woman-with-laptop-looking-big-question-mark_1150-39362.jpg?w=826&t=st=1671038036~exp=1671038636~hmac=684e9b7c9afe2adb7f7dbfb87fdaace6e70a18847b0ed37d469ad47b5c3de4f2' width="50%" />
                    </center>
                    <p>
                        <b>Are you sure you want to leave the {props.item.userType.typeName} group? </b>
                    </p>
                    <div className="clearfix"></div>
                    {
                        isShowError ? <>
                        <div className="alert alert-danger">
                        Could not leave this group.
                        </div>
                        </> : ""
                    }
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="danger" onClick={() => ConfirmRemove()}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i> Leave Group
                    </Button>
                    <Button variant="secondary" onClick={() => SetShowCardDetailModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <a href="#" onClick={() => SetShowCardDetailModal(props.item.userType.isRemovable&&props.myProfile )}>
                <OverlayTrigger
                    key={props.item.id}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${props.item.id}`}>
                            {props.item.userType.typeName}
                        </Tooltip>
                    }
                >
                    <img src={`https://id.wbaforum.org/contents/login/img/${props.item.userType.variableName}.jpg`} className="group-included" />
                </OverlayTrigger>
            </a>


        </>


    )
}