import { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GetBusinessCardImage, GetCardNamedCardURL } from "../../functions/profilepicture";
import { CreateRequest } from "../../Services/BusinessCardService";
import { IsSavedBusinessCard } from "../../Services/UserProfileService";

export default function MyBusinessCard(props) {
    const [isShowBusinessCardModal, setBusinessCardModal] = useState(false);
    const [requestStatus, setStatus] = useState(0);
    const auth = useSelector(state => state.authSlice);

    const sendRequest = () => {
       
        CreateRequest(props.user.id, "", (resp) => {
            if (resp.status) {
                setBusinessCardModal(false);
                setStatus(10);
            }
        });

    }
    useEffect(() => {
        setStatus(0);
        if (props.user.id != null) {
            IsSavedBusinessCard(props.user.id, (resp) => {
                if (resp.status) {
                    setStatus(resp.data.status);
                }

            })
        }
    }, [props.user.id]);
    return (
        <>

            <Modal show={isShowBusinessCardModal} onHide={() => setBusinessCardModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Business Card Request</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: 13 }}>
                    Your business card request will be notified to <b>{props.user.firstname}</b>.

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="success" onClick={sendRequest}>
                        Send Request
                    </Button>
                    <Button variant="secondary" onClick={() => setBusinessCardModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>





            {
                props.user.businessCardImage == null ? <>
                    <div className="communtiy-box business-card">
                        <h3 className="bcard-subtitle card-owner text-center">{props.user.firstname}</h3>
                        <h2 className="box-title">BUSINESS CARD</h2>
                        <div className="text-center">
                            <img src={GetCardNamedCardURL(props.user.masterTitleVariableName)} width="90%" className="single-business-card" />
                            Not uploaded yet
                        </div>


                    </div>
                </> :
                    <>
              
                            <div className="communtiy-box business-card">
                                <h3 className="bcard-subtitle card-owner text-center">{props.user.firstname}</h3>
                                <h2 className="box-title">BUSINESS CARD</h2>
                               {
                                props.user.id == auth.data.id | requestStatus == 20 ? <>
                                 <center>
                                 <img src={GetBusinessCardImage(props.user.businessCardImage)} width="100%"  />
                                 </center>
                                 </>
                                : 
                                <img src={GetCardNamedCardURL(props.user.masterTitleVariableName)} width="90%" className="single-business-card" />
                               }
                                {/* <img src={GetBusinessCardImage(props.user.businessCardImage)} className="single-business-card" /> */}
                                {requestStatus == 10 ?
                                    <center>
                                        <a className="btn btn-sm  btn-warning disabled"><i className="fa fa-clock-o"></i> Pending Request</a>
                                    </center>
                                    :
                                  <>
                                  {
                                    props.user.id != auth.data.id ? <>
                                      <p className="viewall-link text-center"><a href="#here" onClick={() => setBusinessCardModal(true)}>+ Request My Business Card</a></p>
                                    </>
                                    :
                                    <>
                                    </>
                                  }
                                  </>
                                }
                            </div>
                        

                    </>
            }
        </>

    );
}