import axios from 'axios';
import React, { Component, useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom';
import { GetMyCards } from '../../Services/UserCardService';
import { GetUserTypes } from '../../Services/UserTypeService';
import { getLogin } from '../../stores/auth';
import CardTypeComponent from '../Layout/CardTypeComponent';





function Home() {
    const auth = useSelector(state => state.authSlice);
    const [myCards, setMyCards] = useState(false);
    const [userTypes, setUserTypes] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {

        if (auth.isLogon == false) {
           window.location.href='https://id.wbaforum.org'
        }
        else {
            navigate("Profile/"+auth.data.id);
        }
    }, [])
    return (

   <></>
    )


}
export default Home;