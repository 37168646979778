import React, { Component, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SecurePicture from '../../functions/profilepicture';
import { SearchUser } from '../../Services/NetworkService';
import { Dropdown } from 'react-bootstrap';

export default function HeaderComponent() {
    const auth = useSelector(state => state.authSlice);
    const notification = useSelector(state => state.notification)
    const [isShowSearchResult, setShowSearchResult] = useState(false);
    const [searchUserList, setSearchUserList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        document.URL.toLowerCase();

    }, [window.location.href]);
    const closeSearch = (event) => {

        setShowSearchResult(false);
        document.removeEventListener('click', closeSearch);
    }
    const navigateProfile = (id) => {
        navigate(`/Profile/` + id);
    }
    const handleSearch = (e) => {
        var value = e.target.value;
        if (value == null | value == "") {
            setSearchUserList([]);
            setShowSearchResult(false);
        }
        else {
            SearchUser(value, (resp) => {
                setSearchUserList(resp.data);
            })
            setShowSearchResult(true);
        }

        document.addEventListener('click', closeSearch)

    }


    return (
        <>
            <nav className='navbar'>
                <div className="navbar-left">
                    <NavLink className="logo" to="/">
                    <img src="/assets/images/logo.png" />
                    </NavLink>
                    {/* <a href="/" className="logo"><img src="/assets/images/logo.png" /></a> */}

                    <div className="search-box">
                        <img src="/assets/images/search.png" />
                        <input type="text" placeholder="search" onChange={(e) => handleSearch(e)} />
                        {isShowSearchResult ? <>
                            <div className='search-result'>
                                {
                                    searchUserList.map((item) => <>
                                        <div className='result-item' onClick={() => navigateProfile(item.id)}>
                                            <img src={SecurePicture(item.pictureImage)} width="32" height="32" className='rounded-circle' />
                                            <div className='result-item-name'>
                                                {item.firstname}
                                            </div>
                                        </div>

                                    </>)
                                }
                            </div>
                        </> : ""}
                    </div>
                </div>

                <div className="navbar-center">
                    <ul>
                        <li>
                            <NavLink to={`/Profile/${auth?.data?.id}`} className={({ isActive }) => (isActive ? 'active-link' : '')} >
                               <i className='fa fa-home'  style={{fontSize:30, color:"#4a5760"}} ></i>
                               {/* <img src="/assets/images/nav-icons-02.png" /> */}
                               <br />
                                <span>Home</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/MyNetwork" className={({ isActive }) => (isActive ? 'active-link' : '')} >
                            <i className="fas fa-user-friends" style={{fontSize:30, color:"#4a5760"}} ></i><br />
                                {/* <img src="/assets/images/nav-icons-03.png" /> */}
                                <span>My Network</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/BusinessCards" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                                {/* <img src="/assets/images/nav-icons-04.png" />  */}
                                <i class="fas fa-briefcase" style={{fontSize:30, color:"#4a5760"}} ></i>
                                <br /><span>Business Cards</span>
                                {
                                    auth?.data?.businessCardRequestCount > 0 ? <>
                                        <span className='notification-count'>

                                        </span></> : ""
                                }
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/Messages" className={({ isActive }) => (isActive ? 'active-link' : '')}>
                                {/* <img src="/assets/images/nav-icons-05.png" /> */}
                                <i class="fas fa-comment-dots" style={{fontSize:30, color:"#4a5760"}} ></i>

                                
                                <br />
                                <span>Messages</span>
                                {
                                    auth?.data?.unseenMessageCount > 0 ? <>
                                        <span className='message-count'>

                                        </span></> : ""
                                }
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/Notifications" className={({ isActive }) => (isActive ? 'active-link notification-link' : 'notification-link')}>
                                {/* <img src="/assets/images/nav-icons-06.png" /> */}
                                
                                <i class="fas fa-bell" style={{fontSize:30, color:"#4a5760"}} ></i>
                                <br />
                                <span>Notifications</span>
                                {
                                    auth?.data?.unseenNotificationCount > 0 ? <>
                                        <span className='notification-count'>

                                        </span></> : ""
                                }
                            </NavLink>

                        </li>

                        <li>
                            <a href="https://id.wbaforum.org/Forms/MyApplications/Represent" target='_blank' className={({ isActive }) => (isActive ? 'active-link notification-link' : 'notification-link')}>
                                {/* <img src="/assets/images/nav-icons-06.png" /> */}
                                <i className="fas fa-list-alt" style={{fontSize:30, color:"#4a5760"}} ></i>
                                <br />
                                <span>Applications</span>
                                {/* {
                                    auth?.data?.totalApplication > 0 ? <>
                                        <span className='notification-count'>

                                        </span></> : ""
                                } */}
                            </a>

                        </li>
                    </ul>
                </div>
                <div className="navbar-right" style={{display:'flex'}}>
                    <a href="https://campus.wbaforum.org">
                        <img src="/assets/images/menu-item.png" className="right-menu" />
                      
                    </a>
                    <Dropdown align="end">
                                            <Dropdown.Toggle variant="default" id="dropdown-basic" >
                                        {
                                            auth?.data?.pictureImage ? <>
                                                <img src={SecurePicture(auth?.data?.pictureImage,'sm')} width={48} height={48} style={{borderRadius:24, border:'solid 2px #BAAB94'}} />
                                                </>
                                                : <>
                                                <div style={{width:48, height:48, borderRadius:24, backgroundColor:'#BAAB94', color:'white', display:'flex', alignItems:'center', alignContent:'center', justifyContent:'center', textAlign:'center', fontSize:18, fontWeight:'bold'}}>{auth?.data?.firstname[0]}</div>
                                                </>
                                        }
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="https://id.wbaforum.org/MyProfile/BuyCredit" target="_blank" ><i className="fa fa-credit-card"></i> Buy Credits</Dropdown.Item>
                                                <Dropdown.Item href="https://id.wbaforum.org/Forms/MyApplications/Represent" ><i className="fa fa-list-ul"></i> My Applications</Dropdown.Item>
                                                <Dropdown.Item href="https://id.wbaforum.org/MyProfile/ChangePassword" ><i className="fa fa-unlock-alt"></i> Change Password</Dropdown.Item>
                                                {/* <Dropdown.Item href="#/action-1" ><i className="fa fa-trash"></i> Help</Dropdown.Item> */}
                                                <Dropdown.Item href="https://id.wbaforum.org/Login/Logout" ><i className="fa fa-sign-out"></i> Logout</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                   
                </div>
                {/* <div className="right-menu-wrap" id="profileMenu">
                    <div className="right-nav">
                        <div className="user-info">
                            <img src="/assets/images/member-02.jpg" />
                            <div>
                                <h3>Dr Inderjit Singh</h3>
                                <a href="#">See your profile</a>
                            </div>
                        </div>
                        <hr />
                        <a href="#" className="profile-menu-link">
                            <img src="/assets/images/wbaf-round-logo.png" />
                            <p>World Congress 2022</p>
                        </a>
                        <a href="#" className="profile-menu-link">
                            <img src="/assets/images/wbs-logo-round-03.png" />
                            <p>WBAF Business School</p>
                        </a>
                        <a href="#" className="profile-menu-link">
                            <img src="/assets/images/wipa-logo-05.png" />
                            <p>Startup Promotion Agency</p>
                        </a>
                    </div>
                </div> */}
            </nav>
        </>
    )
}




