import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SecurePicture, { GetBusinessCardImage } from '../../functions/profilepicture';
import { ChangeStatus, GetMyBusinessCardFolder, GetPendingRequests } from '../../Services/BusinessCardService';

function BusinessCards() {

    const auth = useSelector(x => x.authSlice);
    const [businessCards, setBusinessCards] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [isShowBusinessCard, setShowBusinessCard] = useState(false);
    const [businessCardImage, setBusinessCardImage] = useState("");
    useEffect(() => {
        _getBusinessCards();
        _getPendingRequests();
    }, []);

    const _getBusinessCards = () => GetMyBusinessCardFolder(100, (resp) => { setBusinessCards(resp.data) });
    const _getPendingRequests = () => GetPendingRequests(1, (resp) => { setPendingRequests(resp.data) });



    const confirmRequest = (id) => {
        ChangeStatus(id, 20, function (resp) {
            if (resp.status) {
                _getBusinessCards();
                _getPendingRequests();
            }

        });

    }

    
    const ignoreRequest = (id) => {
        ChangeStatus(id, 30, function (resp) {
            if (resp.status) {
                _getBusinessCards();
                _getPendingRequests();
            }
        });
    }

    const showBusinessCard = (image) => {
        setBusinessCardImage(image);
        setShowBusinessCard(true);
    }


    return (
        <>
            <Modal show={isShowBusinessCard} onHide={() => setShowBusinessCard(false)}>
                <Modal.Header closeButton>
                    <b>View Business Card</b>
                </Modal.Header>
                <Modal.Body>
                    <img src={GetBusinessCardImage(businessCardImage)} width="100%" className='rounded' />
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={() => setShowBusinessCard(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className='social-container container-fluid row'>
                <div className='col-md-9'>
                    <div className="card card-default mt-2">
                        <div className='card-header'>
                            <b>My Business Card Folder</b>
                        </div>
                        <div className='card-body'>
                            {businessCards.length == 0 ?

                                <>
                                    <center>
                                        <img src='https://img.freepik.com/free-vector/seo-concept-illustration_114360-5846.jpg?w=826&t=st=1670251163~exp=1670251763~hmac=b45fc687491740cdacee74cb05d8664941ef3eed786b26f071ba0bc5eca55123' width="150" />
                                        <div className='mt-3'>
                                            No registered business card found. You can request a business card from the profiles of other users.
                                        </div>

                                    </center>
                                </>
                                :
                                <>
                                    <div className='row'>
                                        {businessCards.map((item, i) =>
                                            <div className='col-md-4' key={i}>
                                                <div className='business-card-item-wrapper'>
                                                    <div className="business-card-item">
                                                        <div className='business-card-item-image' >
                                                            <Link to={"/Profile/" + item.user.id}><img src={SecurePicture(item.user.pictureImage)} width="55" height="55" className='rounded-circle' />
                                                            </Link>
                                                        </div>
                                                        <div className='business-card-item-holder'>
                                                            <Link to={"/Profile/" + item.user.id}>  <b>{item.user.firstname}</b></Link>
                                                            <br />
                                                            <small style={{ color: 'gray' }}>   {item.user.country}</small><br>
                                                            </br>
                                                            {/* <a className='view-card-button' href={item.user.businessCardImage}>View Business Card</a> */}

                                                        </div>
                                                        <div className='business-card-card'>

                                                            <a style={{cursor:'pointer'}} onClick={() => showBusinessCard(item.user.businessCardImage)}>
                                                                <img src={GetBusinessCardImage(item.user.businessCardImage)} className="img-thumbnail" width="70" />
                                                            </a>
                                                        </div>

                                                        <div className='clearfix'></div>
                                                    </div>
                                                </div>



                                            </div>
                                        )}
                                    </div>
                                </>


                            }

                        </div>

                    </div>
                </div>

                <div className='col-md-3'>
                    <div className="card  mt-2">
                        <div className='card-header'>
                            <b >Business Card Requests</b>
                        </div>
                        <div className='card-body'>
                            {pendingRequests.length == 0 ? <>
                                <center>
                                    <img src='https://img.freepik.com/free-vector/seo-concept-illustration_114360-5846.jpg?w=826&t=st=1670251163~exp=1670251763~hmac=b45fc687491740cdacee74cb05d8664941ef3eed786b26f071ba0bc5eca55123' width="100" />
                                    <div className='mt-3'>
                                        <small>
                                            No pending requests. When someone wants to see your business card, the request will appear here.
                                        </small>
                                    </div>

                                </center>



                            </> :
                                <>
                                    {pendingRequests.map((item, i) =>

                                        <>
                                            <div className='pending-request-item' key={i}>
                                                <div className='pending-request-item-image'>
                                                    <Link to={"/Profile/" + item.createdByUser.id}><img src={SecurePicture(item.createdByUser.pictureImage)} width="50" height="50" className='rounded-circle' />
                                                    </Link>
                                                </div>

                                                <div className='pending-request-item-content'>
                                                    <div className='pending-request-item-name'>
                                                        <Link to={"/Profile/" + item.createdByUser.id} target="_blank"> {item.createdByUser.firstname}</Link>
                                                    </div>
                                                    <div className='pending-request-item-subtitle'>
                                                        <small style={{ color: 'gray' }}>   {item.createdByUser.masterTitle}, {item.createdByUser.country}</small>
                                                    </div>

                                                </div>

                                                <div className='actions'>
                                                    <div className="d-grid gap-1">
                                                        <a className='btn btn-sm btn-success' onClick={() => confirmRequest(item.id)}>Confirm</a> 
                                                        <a className='btn btn-sm btn-outline-danger' onClick={() => ignoreRequest(item.id)}>Ignore</a>
                                                    </div>
                                                </div>




                                            </div>




                                        </>

                                    )}
                                </>


                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="clearfix"></div>


        </>

    );
}

export default BusinessCards;