import BaseRequest, { PostRequest } from './RequestService';
import { UrlList } from './UrlList';
export async function GetRecentPosts(userID,page,postFilterType,userTypeID,categoryType,callback) {
    BaseRequest(UrlList.GetRecentPosts+'?userID='+userID+"&page="+page+"&postFilterType="+postFilterType+"&userTypeID="+userTypeID+"&categoryType="+categoryType, callback)

}

export async function GetRecentComments(postID, callback) {
    BaseRequest(UrlList.GetRecentComments + "?postID=" + postID, callback)

}

export async function LikePost(postID, callback) {
    BaseRequest(UrlList.LikePost + "?postID=" + postID, callback)

}
export async function CreateComment(postID, comment, callback) {
    PostRequest(UrlList.CreateComment, { postID, comment }, callback)

}

export async function CreateNewPost(data, callback) {
    PostRequest(UrlList.CreatePost, data, callback, true)

}

export async function GetPreview(url, callback) {
    BaseRequest(UrlList.GetPreview + "?url=" + url, callback)

}
export async function DeletePost(postID, callback) {
    BaseRequest(UrlList.DeletePost + "?postID=" + postID, callback)

}

export async function GetMyPostCategories(callback) {
    BaseRequest(UrlList.GetMyPostCategories, callback)
}


export async function DeleteComment(commentID,callback) {
    PostRequest(UrlList.DeleteComment+"?commentID="+commentID,null, callback)
}

export async function GetLikes(postid,callback) {
    BaseRequest(UrlList.GetLikesByPostID+"?postID="+postid, callback)
}

export async function GetSinglePost(postid,callback) {
    BaseRequest(UrlList.GetSinglePost+"?postID="+postid, callback)
}
