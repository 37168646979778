import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import SecurePicture, { GetCardNamedCardURL } from '../../functions/profilepicture';
import { GetMyNetwork } from '../../Services/NetworkService';
import { GetMyCards } from '../../Services/UserProfileService';
import '../../mynetwork.css';
import { Button, Modal } from 'react-bootstrap';
import UserListLoader from '../Partials/Loaders/UserListLoader';
import MyNetworkLoader from '../Partials/Loaders/MyNetworkLoader';
import { GetMyPostCategories } from '../../Services/FeedService';
export default function MyNetwork() {

    const [userList, setUserList] = useState([]);
    const [isLoaded, setLoadedStatus] = useState(false);
    const [myCardList, setMyCardList] = useState([]);
    const [myCategories, setMyCategories] = useState([]);
    const [selectedCardType, setSelectedCardType] = useState({ id: -1, text: "All Cards", modal: false });
    const [isShowCardTypeModal, setShowCardTypeModal] = useState(false);
    useEffect(() => {

        GetList();
        GetMyPostCategories((resp) => {
            setMyCategories(resp.data);
        });
        GetMyCards((resp) => {
            setMyCardList(resp.data);
        });

    }, []);

    const GetList = (relID, type=1) => {
        if (type == 1) {
            console.log("type=1");
            GetMyNetwork(relID, -1, (resp) => {
                setUserList(resp.data);
                setLoadedStatus(true);
            });
        }
        else if (type == 2) {
            console.log("type=2");
            GetMyNetwork(-1, relID, (resp) => {
                setUserList(resp.data);
                setLoadedStatus(true);
            });
        }

    }
    const handleChangeCardType = (id, type, text) => {
        setSelectedCardType({ id, text });
        GetList(id, type);
        setShowCardTypeModal(false);

    }


    return (
        <>
            <Modal show={isShowCardTypeModal} onHide={() => setShowCardTypeModal(false)}>
                <Modal.Header>
                    Filter Card
                </Modal.Header>
                <Modal.Body>
                    <div className='filter-card-list'>
                        {myCategories.map((item) => <>

                            <div className='filter-card-item' onClick={() => handleChangeCardType(item.relID, item.type, item.categoryName)}>
                                {
                                    item.type == 1 ? <>
                                        <img src={GetCardNamedCardURL(item.imageUrl)} width="32" />

                                    </>
                                        :
                                        <>
                                            <img src={item.imageUrl} width="32" />
                                        </>
                                }
                                <div className='filter-car-item-name'>{item.categoryName}</div>
                            </div>

                        </>)}

                        {/* {myCardList.map((item) => <>

<div className='filter-card-item' onClick={() => handleChangeCardType(item.userType.id, item.userType.typeName)}>
    <img src={GetCardNamedCardURL(item.userType.variableName)} width="32" />
    <div className='filter-car-item-name'>{item.userType.typeName}</div>
</div>

</>)} */}
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={() => setShowCardTypeModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <div className='social-container container-fluid row'>
                <div className='col-md-12 mb-2'>
                    <span className='filter-type-wrapper' onClick={() => setShowCardTypeModal(true)}>
                        {selectedCardType.text} <i className='fa fa-caret-down pull-right'></i>
                    </span>
                    {/* <select className='form-select form-select-sm' onChange={(e) => handleChangeCardType(e)}>
                            <option value="-1">All Cards</option>
                            {myCardList.map((item) => <option value={item.userType.id}>{item.userType.typeName}</option>)}
                        </select> */}
                    <div className='clearfix'></div>
                </div>
                <div className='col-md-12'>
                    <div className="card card-default mt-2">
                        <div className='card-header'>
                            <b>My Network</b>
                        </div>
                        <div className='card-body'>
                            {userList.length == 0 && isLoaded == true ?
                                <>
                                    <center>
                                        <img src='https://img.freepik.com/free-vector/seo-concept-illustration_114360-5846.jpg?w=826&t=st=1670251163~exp=1670251763~hmac=b45fc687491740cdacee74cb05d8664941ef3eed786b26f071ba0bc5eca55123' width="150" />
                                        <div className='mt-3'>
                                            No registered business card found. You can request a business card from the profiles of other users.
                                        </div>

                                    </center>
                                </>
                                :
                                isLoaded == false ? <>

                                    <>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <MyNetworkLoader />
                                                <MyNetworkLoader />
                                                <MyNetworkLoader />
                                                <MyNetworkLoader />
                                            </div>
                                            <div className='col-md-4'>
                                                <MyNetworkLoader />
                                                <MyNetworkLoader />
                                                <MyNetworkLoader />
                                                <MyNetworkLoader />
                                            </div>
                                            <div className='col-md-4'>
                                                <MyNetworkLoader />
                                                <MyNetworkLoader />
                                                <MyNetworkLoader />
                                                <MyNetworkLoader />
                                            </div>
                                        </div>
                                    </>
                                </> : ""


                            }
                            <div className='row'>
                                {userList.map((item, i) =>
                                    <div className='col-md-4' key={i}>
                                        <div className='business-card-item-wrapper'>
                                            <div className="business-card-item">
                                                <div className='business-card-item-image' >
                                                    <Link to={"/Profile/" + item.id}><img src={SecurePicture(item.pictureImage, "sm")} width="55" height="55" className='rounded-circle' />
                                                    </Link>
                                                </div>
                                                <div className='business-card-item-holder'>
                                                    <Link to={"/Profile/" + item.id}>  <b>{item.firstname}</b></Link>
                                                    <br />
                                                    <small style={{ color: 'gray' }}>  {item.masterTitle}, {item.country}</small><br>
                                                    </br>
                                                    {/* <a className='view-card-button' href={item.user.businessCardImage}>View Business Card</a> */}

                                                </div>
                                                <div className='business-card-card'>

                                                    {/* <a href='#' className='btn btn-sm btn-secondary'>
                                                                Profile
                                                            </a> */}
                                                </div>

                                                <div className='clearfix'></div>
                                            </div>
                                        </div>



                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </>
    )

}