import { UrlList } from "./UrlList";
import BaseRequest, { PostRequest } from './RequestService';

export function GetUserProfile(id,callback) {
    BaseRequest(UrlList.GetUserProfile+"/"+id,callback);

}

export function GetMyCards(callback) {
    BaseRequest(UrlList.MyProfile_GetCards,callback);

}

export function SaveBiography(body,callback) {
    PostRequest(UrlList.PostSaveBiography,body,callback,true);

}
export function SaveBusinessCardToFolder(userID,callback) {
    PostRequest(UrlList.SaveCardToFolder,{userID},callback);

}

export function IsSavedBusinessCard(userID,callback) {
    BaseRequest(UrlList.IsSavedBusinessCard+"?userID="+userID,callback);

}


export function RemoveCard(cardID,callback) {
  
    PostRequest(UrlList.RemoveCard,{cardID:cardID},callback);

}
