import { useState } from "react"
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GetCardBannerURL, GetCardNamedCardURL } from "../../functions/profilepicture";
import { ConfirmJoinCard } from "../../Services/UserTypeService";


export default function CardTypeComponent(props) {
    const [modalIsShow, setModal] = useState(false);
    const [isJoined, setJoined] = useState(false);
    const [isJoinModalShow, setJoinModalShow] = useState(false);
    const [isCertRequiredModalShow, setCertRequiredModalShow] = useState();
    const auth = useSelector(state => state.authSlice);

    const JoinConfirm = () => {
        ConfirmJoinCard(props.item.id, (res) => {
            if (res.status) {
                setJoined(true);
                setTimeout(() => { window.location.reload() }, 2500);
            }

        });
    }
    return (
        <>
            <Modal show={isJoinModalShow} onHide={() => setJoinModalShow(false)}>
                <Modal.Header>
                    <b>Join</b>
                </Modal.Header>
                <Modal.Body>
                    Apply here to become a Senator or International Partner: <a className="" style={{ color: "blue" }} href="https://www.wbaforum.org/represent">www.wbaforum.org/represent</a>
                </Modal.Body>

            </Modal>

            <Modal show={isCertRequiredModalShow} onHide={() => setCertRequiredModalShow(false)}>
                <Modal.Header>
                    <b>Join</b>
                </Modal.Header>
                <Modal.Body>
                    You need a {props.item.typeName} Certificate to join this group. <br /> Please get in Contact with: <a href="mailto:Christina.McGimpsey@wbaforum.org"> Christina.McGimpsey@wbaforum.org </a>
                </Modal.Body>

            </Modal>


            <Modal show={modalIsShow} onHide={() => setModal(false)} size="lg">
                <Modal.Header closeButton>
                    <b>Join</b>
                </Modal.Header>
                <Modal.Body >
                    {isJoined == true ? <>
                        <div className="row">
                            <div className="col-12">
                                <div className="alert alert-success">
                                    <i className="fa fa-check"></i> You have successfully joined the card.
                                </div>
                            </div>
                        </div>


                    </> : <>
                        <div className="row">
                            <div className="col-4">
                                <img className="rounded img-thumbnail" style={{ width: '100%' }} src={GetCardNamedCardURL(props.item.variableName)} />
                            </div>
                            <div className="col-8">
                                <h5>
                                    {props.item.typeName}
                                </h5>
                                {
                                    auth.data.baseCardCount == 0 && props.item.typeCategory == 30 ? <>
                                        <div className="alert alert-warning">

                                            Only Senior Associates, High Commissioners, Senior Senators, Senators and International Partners are eligible to join International Committees. You can apply here to become a Senator or International Partner: <a className="" href="https://www.wbaforum.org/represent" target="_blank" style={{ color: 'blue' }}>www.wbaforum.org/represent</a>
                                        </div>
                                    </> : <>
                                        {props.item.typeCategory == 30 ? <>
                                            <div className="">
                                                {props.item.typeName} You can join this committee for one year free of charge.
                                              
                                             <div className="clearfix mt-2"> </div>
                                                <a className="btn btn-success btn-sm" onClick={() => JoinConfirm()}>
                                                        <i className="fa fa-check"></i> Join Now
                                                    </a>
                                            </div>
                                        </> :
                                            <>
                                                <span>
                                                    You can join this group for one year for {props.item.annualRenewalFee} credits.
                                                    {/* For {props.item.annualRenewalFee} credits, you join the 1-year <b>{props.item.typeName}</b> card. */}
                                                </span>
                                                <br />
                                                <p> <br />
                                                    <b>Your Balance:</b> {auth.data.cardCredit} Credit
                                                </p>
                                                {auth.data.cardCredit >= props.item.annualRenewalFee ? <>

                                                    <a className="btn btn-success btn-sm" onClick={() => JoinConfirm()}>
                                                        <i className="fa fa-check"></i> Join Now
                                                    </a>
                                                </>
                                                    :

                                                    <>
                                                        <div className="alert alert-warning"><small>
                                                            You do not have enough credits to join this group. You need to purchase  {props.item.annualRenewalFee} credits.
                                                        </small></div>
                                                        <a className="btn btn-warning btn-sm" href="https://id.wbaforum.org/myprofile/buycredit" target="_blank">
                                                            <i className="fa fa-shopping-cart" aria-hidden="true"></i> Buy Credit
                                                        </a>

                                                    </>}
                                            </>



                                        }
                                    </>
                                }

                            </div>
                        </div>


                    </>}



                </Modal.Body>
                {/* <Modal.Footer style={{ justifyContent: 'center' }}>

                 
                </Modal.Footer> */}
            </Modal>

            <div className="single-community-box">
                <div className="community-img-box">
                    <img className="single-community-img" style={{ borderRadius: "5px" }} src={GetCardNamedCardURL(props.item.variableName)} />
                </div>
                <div className="community-details">
                    <h3 className="community-name">{props.item.typeName}</h3>
                    {/* <p className="total-member">{props.item.cardCount} Members</p> */}


                    {
                        props.item.isCertificateRequired == true ?
                            <>
                                {
                                    props.item.myStatus == true ?
                                        <>
                                            <a className="join-link disabled" style={{ float: 'right', backgroundColor: '#097b07' }}>Joined </a>
                                        </>
                                        :
                                        <>
                                            <a className="join-link" onClick={() => setCertRequiredModalShow(true)} target="_blank" style={{ float: 'right', cursor: "pointer" }}>+ Join</a>
                                        </>
                                }
                            </>
                            :
                            <>
                                {
                                    props.item.isRegistrable == false ?
                                        <>
                                            {
                                                props.item.myStatus == true ?
                                                    <>
                                                        <a className="join-link disabled" style={{ float: 'right', backgroundColor: '#097b07' }}>Joined </a>
                                                    </>
                                                    :
                                                    <>
                                                        <a className="join-link" onClick={() => setJoinModalShow(true)} target="_blank" style={{ float: 'right' }}>+ Join</a>
                                                    </>
                                            }

                                        </>
                                        :
                                        props.item.myStatus == true ?
                                            <>
                                                <a className="join-link disabled" style={{ float: 'right', backgroundColor: '#097b07' }}>Joined </a>
                                            </>
                                            :
                                            <>
                                                <a className="join-link" style={{ float: 'right', cursor: 'pointer' }} onClick={() => setModal(true)}>+ Join</a>
                                            </>
                                }
                            </>
                    }

                </div>
            </div>
        </>


    )
}