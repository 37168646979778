export const UrlList = {
    baseURL : "https://api-network.wbaforum.org",
    // baseURL: "https://localhost:7149",


   MyProfile_GetCards: "UserProfile/GetMyCards",
   GetUserTypes: "UserTypes/GetUserTypes",
   GetMyCommittee: "UserTypes/GetMyCommittee",
   ConfirmJoinCard: "UserTypes/ConfirmJoinCard",
   GetUserProfile: "UserProfile/GetUserProfile",
   PostSaveBiography: "UserProfile/SaveBiography",
   RemoveCard: "UserProfile/RemoveCard",

   GetMyBusinessCardFolder: "BusinessCards/GetMyFolder",
   CreateRequest: "BusinessCards/CreateRequest",
   ChangeStatus: "BusinessCards/ChangeStatus",
   GetPendingRequest: "BusinessCards/GetPendingRequest",
   IsSavedBusinessCard: "BusinessCards/IsSavedBusinessCard",


   GetRecentPosts: "Posts/GetPosts",
   GetRecentComments: "Posts/GetComments",
   LikePost: "Posts/LikePost",
   CreateComment: "Posts/CreateComment",
   DeleteComment: "Posts/DeleteComment",
   CreatePost: "Posts/CreatePost",
   DeletePost: "Posts/Delete",
   GetPreview: "Preview/GetPreview",
   GetLikesByPostID: "Posts/GetlikesByPostID",
   GetSinglePost: "Posts/GetSinglePost",
   GetMyNetwork: "MyNetwork/GetMyNetwork",
   MyNetwork_SearchUser: "MyNetwork/Search",


   GetMyPostCategories: "Posts/GetMyPostCategories",
   GetConversations: "Conversations/GetConversations",
   GetMessagesByConversationID: "Conversations/GetMessages",
   CreateMessage: "Conversations/CreateMessage",
   SearchUser: "Conversations/SearchUser",
   GetConversationByID: "Conversations/GetConversationByID",
   DeleteConversation: "Conversations/DeleteConversation",

   GetNotifications: "Notifications/GetLastNotifications",
   MarkAsReadNotification: "Notifications/MarkAsReadNotification",

   Content_GetEvents: "Events/GetEvents",



   GetCountries: "Countries/GetCountries",
   JoinCountryGroup: "Countries/JoinCountryGroup",
}