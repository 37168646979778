import React from "react"
import ContentLoader from "react-content-loader"

const MyNetworkLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={700}
    height={124}
    viewBox="0 0 700 124"
    backgroundColor="#d4d4d4"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="30" cy="25" r="25" /> 
    <rect x="60" y="12" rx="3" ry="3" width="170" height="7" /> 
    <rect x="60" y="30" rx="3" ry="3" width="170" height="7" /> 
    

    <circle cx="30" cy="90" r="25" /> 
    <rect x="60" y="77" rx="3" ry="3" width="170" height="7" /> 
    <rect x="60" y="94" rx="3" ry="3" width="170" height="7" /> 
    


  </ContentLoader>
)

export default MyNetworkLoader

