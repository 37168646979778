import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetUserTypes } from "../../Services/UserTypeService";
import UserListLoader from "../Partials/Loaders/UserListLoader";
import CardTypeComponent from "./CardTypeComponent";


export default function OtherCardsComponent(props) {
    const [userTypes, setUserTypes] = useState(false);
    useEffect(() => {
        if(props.pageSize == null)
        {
            props.pageSize = 3;
        }

        GetUserTypes(props.cardType, props.pageSize,true, (resp) => { setUserTypes(resp.data) });

    }, []);
    return (
        <div className="communtiy-box world-leaders">
            {props.cardType == "10" ?
                <h2 className="box-title">JOIN WORLD LEADERS</h2>
                :
                props.cardType == "20" ?
                    <h2 className="box-title">JOIN OTHER COMMUNITIES TOO</h2>
                    :
                    <h2 className="box-title">JOIN GLOBAL COMMITTEES </h2>
            }

            {userTypes == false ? <>
                <UserListLoader />
            </> :
                userTypes.map((item, i) => <CardTypeComponent key={i} item={item} />)

            }
            <div className="clearfix"></div>
            { props.cardType != "30" && 2 < parseInt(props.pageSize) ? <p className="viewall-link"><Link to={`/Cards/${props.cardType}`}>+ View all</Link></p> : ""}
        </div>
    )
}