import { useEffect, useState } from "react"
import CommentItem from "./CommentItem"
import UserListLoader from "../../Partials/Loaders/UserListLoader"
import CommentListLoader from "../../Partials/Loaders/CommentListLoader";
import { GetRecentComments } from "../../../Services/FeedService";

export default function Comments(props) {
    const [commentList, setCommentList] = useState([]);
    const[isLoaded,setLoaded] = useState(false);
    useEffect(() => {
        GetRecentComments(props.postID, (resp) => {
  
            setCommentList(resp.data);
          setLoaded(true);
            
           

        });



    }, []);
    return (
        <div className="post-comments">
            {commentList.length == 0 && isLoaded == false && props.commentCount > 0 ? <CommentListLoader /> :
                <>
                  {commentList.map((item,i)=>  <CommentItem  key={i} item={item} />)}
                </>
            }
        </div>
    )
}