import { useEffect, useState } from "react"
import { GetLastNotifications, MarkAsReadNotification } from "../../Services/NotificationService";
import SecurePicture from "../../functions/profilepicture";
import '../../notifications.css';
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { markSeenNotifications } from "../../stores/auth";
export default function Notifications() {
    const [notificationList, setNotifications] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        GetLastNotifications(1, (resp) => {
            setNotifications(resp.data);
        })

        dispatch(markSeenNotifications());
    }, []);

    const generateNotificationText = (item) =>{
        if(item.relType == 1)
        {
            return "liked your post.";
        }
        else if(item.relType == 2)
        {
            var comment = item.notificationText;
            if(comment == null)
            {
                comment = "";
            }
            if(comment.length > 100)
            {
                comment = comment.substring(0, 97)+"...";
            }
            return "commented on your post: "+comment;
        }
        else if(item.relType == 3)
        {
            
            return "Business Card Request";
        }
        else if(item.relType == 4)
        {
            
            return "Uploaded a new Business Card";
        }

    }

    const handleClickNotification = (item) =>{
        if(!item.isRead)
        {
            MarkAsReadNotification(item.id);
            item.isRead = true;
        }
        if(item.relType == 1 | item.relType == 2)
        {
            
            navigate(`/SinglePost/`+item.relID);
        }
        else if(item.relType == 3)
        {
            navigate(`/BusinessCards`);
        }
        else if(item.relType == 4)
        {
            navigate(`/Profile/`+item.relUser.id);
        }
    
    }
    return (<>
        <div className="social-container container-fluid">
            <div className="col-6 mx-auto">
                <div className="notification-wrapper">
                    {
                        notificationList.map((item, i) => <>

                            <div className={item.isRead ? "notification-item" : "notification-item new"} onClick={()=>handleClickNotification(item)}>
                               {/* {
                                !item.isRead ?  <div> •</div> : <div>  </div> 
                               } */}
                                <img src={SecurePicture(item.relUser.pictureImage,"sm")} className="rounded-circle" width={42} height={42} />
                                <div className="notification-text">
                                    <span className="user">{item.relUser.firstname}  </span> {generateNotificationText(item)}
                                </div>
                                <div className="notification-time" title={item.date + " UTC"}>
                                    {moment.utc(item.date).local().startOf('seconds').fromNow()}
                                </div>
                            </div>


                        </>)
                    }


                </div>
            </div>
        </div>



    </>)
}