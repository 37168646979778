import BaseRequest, { PostRequest } from './RequestService';
import { UrlList } from './UrlList';
export async function GetConversations(callback) {
    BaseRequest(UrlList.GetConversations, callback)

}

export async function GetMessages(conversationID,page,callback) {
    BaseRequest(UrlList.GetMessagesByConversationID+"?conversationID="+conversationID+"&page="+page, callback)

}

export async function CreateMessage(message,callback) {
    PostRequest(UrlList.CreateMessage,message, callback)

}
export async function SearchUser(name,callback) {
    BaseRequest(UrlList.SearchUser+"?name="+name, callback)

}
export async function GetConversationByID(id,callback) {
    BaseRequest(UrlList.GetConversationByID+"?id="+id, callback)

}

export async function DeleteConversation(id,callback) {
    PostRequest(UrlList.DeleteConversation,id, callback,false);

}