
import HeaderComponent from './Components/Layout/HeaderComponent';
import Home from './Components/Pages/Home';
import { Routes, Route, Link, NavLink, BrowserRouter } from 'react-router-dom';
import Messages from './Components/Pages/Messages';
import MyNetwork from './Components/Pages/MyNetwork';

import Profile from './Components/Pages/Profile';
import BusinessCards from './Components/Pages/BusinessCards';
import Cards from './Components/Pages/Cards';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLogin } from './stores/auth';
import ContentLoader from 'react-content-loader';
import { ErrorBoundary } from './functions/ErrorBoundaries';
import FullScreenLoader from './Components/Partials/Loaders/FullScreenLoader';
import Notifications from './Components/Pages/Notifications';
import SinglePost from './Components/Pages/SinglePost';

function App() {
  // const auth = useSelector(state => state.authSlice);
  // const dispatch = useDispatch();
  // dispatch(getLogin());
  // const { user } = useSelector(state => state.auth);
  // dispatch(getLogin());



  const auth = useSelector(state => state.authSlice);
  const dispatch = useDispatch();
  useEffect(() => {

    dispatch(getLogin());


  }, []);

  return (
    <>
      <ErrorBoundary>
        {
          auth?.loading == true || auth?.isLogon == false ?
            <FullScreenLoader />
            :
            <>
              <BrowserRouter>
                <HeaderComponent />
                <div className="">

                  <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/Messages' element={<Messages />} />
                    <Route path='/Messages/:id' element={<Messages />} />
                    <Route path='/MyNetwork' element={<MyNetwork />} />
                    <Route path='/BusinessCards' element={<BusinessCards />} />
                    <Route path='/Profile/:id' element={<Profile />} />
                    <Route path='/Cards/:id' element={<Cards />} />
                    <Route path='/Notifications' element={<Notifications />} />
                    <Route path='/SinglePost/:id' element={<SinglePost />} />
                  </Routes>


                </div>
              </BrowserRouter>
            </>
        }
      </ErrorBoundary>




    </>

  );
}

export default App;
