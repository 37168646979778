import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GetSinglePost } from "../../Services/FeedService";
import Post from "../Layout/FeedComponents/Post";

export default function SinglePost() {
    let { id } = useParams();
    const [singlePost, setSinglePost] = useState(null);
    useEffect(() => {
        GetSinglePost(id, (resp) => {
            setSinglePost(resp.data);
        });
    }, [id]);

    return (
        <>
            <div className="row">
                <div className="col-4 mx-auto">
                    {
                        singlePost == null ? "" : <Post item={singlePost} />
                    }
                </div>
            </div>
        </>
    );
}