import ContentLoader from "react-content-loader";

export default function FullScreenLoader()
{
    return(
<div className="page-loader-wrapper">
<div className='page-loader'>

<img src='/assets/images/logo.png' />

<ContentLoader />
<progress />
</div>
</div>

    );
}